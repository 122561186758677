import React, { Fragment, useState } from 'react';

import styled from 'styled-components';
import { Container, Row, Col } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

import useTabs from '../../utils/tabWithState';
import ErrorBoundary from '../../utils/errorBoundary';
import NetAcvTool from './netAcvTool';
import ARUpdateTab from './aRUpdateTool';

const ToolTabStyle = styled.div`
  .sidebarForTool {
    border-right: thin solid grey;
    padding: 1rem;
    .title {
      width: 100%;
      display: inline-block;
      span {
        font-size: 1.1rem;
      }
      .hideButton {
        float: right;
        font-size: 0.8rem;
        transform: translateY(5px);
        &:hover {
          text-decoration: underline;
        }
      }
    }

    .buttonForSidebar {
      color: darkblue;
      font-size: 0.9rem;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .hidden {
    display: none;
  }

  .contentForTool {
    border-radius: 5px;
    height: calc(100vh - 5rem);
  }
`;

/**
 * Contains the Tool Tab that
 *
 * @returns {object} The rendered object for tool tab
 */
function ToolTab() {
  const { getActiveTabTitle, setActiveTab, getAllTabTitles, getTabs } = useTabs(
    {
      initialTabs: {
        'Net Acv Calculations': <NetAcvTool />,
        'AR Update': <ARUpdateTab />
      },
      initialActiveTab: 'Net Acv Calculations'
    }
  );

  const tabTitles = getAllTabTitles();
  const sideBarButtons = tabTitles.map(name => {
    return (
      <div key={name} className="buttonForSidebar">
        <span onClick={() => setActiveTab(name)}>{name}</span>
      </div>
    );
  });

  const tabs = getTabs();
  const content = Object.keys(tabs).map(tabName => (
    <Fragment key={tabName}>
      <div className={tabName !== getActiveTabTitle() ? 'hidden' : ''}>
        {tabs[tabName]}
      </div>
    </Fragment>
  ));

  const [sideBarOpen, setSideBarOpen] = useState(true);

  const contentSize = sideBarOpen ? 10 : 12;

  return (
    <ErrorBoundary>
      <ToolTabStyle>
        <Container fluid className="">
          <Row>
            {sideBarOpen ? (
              <Col sm={{ size: 2, order: 1 }} className="sidebarForTool mt-2">
                <span className="title">
                  <span>Tools</span>{' '}
                  <span className="hideButton">
                    <FontAwesomeIcon
                      icon={faEye}
                      onClick={() => {
                        setSideBarOpen(false);
                      }}
                    />
                  </span>
                </span>
                <hr />
                {sideBarButtons}
              </Col>
            ) : null}
            <Col
              sm={{ size: contentSize, order: 2 }}
              className="contentForTool mt-2"
            >
              {content}
            </Col>
          </Row>
        </Container>
      </ToolTabStyle>
    </ErrorBoundary>
  );
}

export default ToolTab;
