import React from 'react';
import { Jumbotron, Container } from 'reactstrap';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { merge } from 'lodash-es';

import { timedFetch, recordTime } from './utils/customFetch';
import config from '../config';
import AuthContext from './context';
import { sendEvent } from './utils/eds';

// Used for getting a stateful time recording callback
const [recordTimeCallback, getTimeData] = recordTime();

class AuthCheckAndSignIn extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loggedIn: false,
      username: null,
      loadPerfData: {
        fullyLoaded: false
      }
    };
  }

  componentDidMount() {
    timedFetch('auth', '<>', recordTimeCallback, config.urls.authCheck, {
      credentials: 'include'
    })
      .then(res => res.json())
      .then(
        result => {
          this.setState({
            loggedIn: result.loggedIn,
            username: result.username,
            userID: result.identifier
          });
        },
        error => {
          this.setState({
            loggedIn: false,
            username: null,
            userID: null,
            error
          });
        }
      );
  }

  componentDidUpdate() {
    if (this.state.loggedIn && !this.state.loadPerfData.fullyLoaded) {
      try {
        const td = getTimeData();
        if (td && td.auth && td.auth['<>']) {
          const tt = td.auth['<>'];
          if (!tt.status || tt.timeTaken > 700) {
            // cause for concern.
            const extraData = {
              userID: this.state.userID,
              totalTimeTaken: tt.timeTaken,
              requestType: 'authSlowOrFail'
            };

            sendEvent('performance', extraData);
          }
          this.setState(
            merge(this.state, { loadPerfData: { fullyLoaded: true } })
          );
        }
      } catch (e) {
        console.log(e);
      }
    }
  }

  render() {
    const loginFailed = /^\/loginFailure/.test(this.props.location.pathname);
    if (this.state.loggedIn) {
      return (
        <div>
          <AuthContext.Provider value={this.state}>
            {this.props.children}
          </AuthContext.Provider>
        </div>
      );
    }
    return (
      <Jumbotron fluid>
        {loginFailed ? (
          <>
            <h1>
              Hey, it seems that you don&apos;t have Access to Hawkeye yet.
            </h1>
            <p>
              Please request via
              <a
                href="https://enigma.bsstag.com/access/requestAccess?accesses=access_hawkeye"
                rel="noopener noreferrer"
                target="_blank"
              >
                {' '}
                Enigma
              </a>{' '}
              to get access to the app.
              <br />
              After getting access, click{' '}
              <a href={`${config.hostname}/auth/google`}>here</a> to login.
            </p>
          </>
        ) : (
          <Container fluid>
            <h1 className="display-3">Welcome to Hawkeye</h1>
            <p className="lead">
              Click <a href={`${config.hostname}/auth/google`}>here</a> to
              login.
            </p>
          </Container>
        )}
      </Jumbotron>
    );
  }
}

AuthCheckAndSignIn.propTypes = {
  children: PropTypes.node,
  location: PropTypes.any
};

export default withRouter(AuthCheckAndSignIn);
