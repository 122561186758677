import React from 'react';
import Proptype from 'prop-types';
import { mapValues, values } from 'lodash-es';
import { Col, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';

import { postJson } from '../utils/customFetch';
import config from '../../config';
import { DenseTable } from '../utils/_styles';
import CustomTable from '../utils/customTable';
import { ModalContext } from '../utils/alertModal';
import { Legend } from '../utils/legend';

dayjs.extend(customParseFormat);
dayjs.extend(utc);

/** Get Role of user from the Role Set
 *
 * @param {Array} roleArray The Role array of a User
 * @returns {string} The Precedent Role of the given roles
 */
function getRole(roleArray) {
  const role = new Set(roleArray);
  if (role.has('owner')) return 'Owner';
  if (role.has('admin')) return 'Admin';
  return 'User';
}

/**
 * Get Acess of user from the boolean field
 *
 * @param {Object} row the user data object containing user's product info
 * @param {string} key the product name e.g. applive
 * @returns {string} access value as Yes/No
 */
function getAccess(row, key) {
  const hasAccess = row.access[key];
  const usage = row.usage[key] ? ` (${row.usage[key]} secs)` : '';
  return hasAccess ? `Yes${usage}` : `No${usage}`;
}

/** Returns formatted value of location
 *
 * @param {object} location object
 * @returns {string} formatted location
 */
function getLocation(location) {
  return [location.country, location.city].filter(Boolean).join(', ');
}

/**
 * @param row row data from table
 * @param addMessage function for displaying toasts
 */
async function sendContactToSf(row, addMessage) {
  addMessage(row.email, `Creating SFDC contact`, 2000);
  let firstName = '';
  let lastName = '';
  let words = row.name.split(' ');
  if (words.length <= 1) {
    lastName = row.name;
  } else {
    firstName = words.slice(0, words.length - 1).join(' ');
    lastName = words[words.length - 1];
  }
  let payload = {
    email: row.email,
    first_name: firstName,
    last_name: lastName,
    group_id: row.groupId.toString(),
    user_id: row.id.toString(),
    city: row.location.city,
    country: row.location.country
  };

  postJson(config.urls.createContact, payload)
    .then(result => {
      addMessage(
        row.email,
        result && result.message ? result.message : JSON.stringify(result),
        2000
      );
    })
    .catch(error => {
      addMessage(row.email, JSON.stringify(error), 2000);
    });
}

class UserTable extends React.Component {
  constructor(props) {
    super(props);

    const columns = [
      {
        Header: 'Sr. No',
        accessor: () => 'PENDING',
        disableSorting: true,
        disableFilter: true
      },
      { Header: 'User Id', accessor: 'id' },
      { Header: 'Name', accessor: 'name' },
      { Header: 'Email', accessor: 'email' },
      { Header: 'Role', accessor: row => getRole(row.role) },
      {
        Header: 'Access',
        columns: [
          {
            Header: 'Live (duration)',
            accessor: row => getAccess(row, 'live')
          },
          {
            Header: 'Automate (duration)',
            accessor: row => getAccess(row, 'automate')
          },
          {
            Header: 'App Live (duration)',
            accessor: row => getAccess(row, 'appLive')
          },
          {
            Header: 'App Automate (duration)',
            accessor: row => getAccess(row, 'appAutomate')
          },
          {
            Header: 'Screenshot',
            accessor: row => getAccess(row, 'screenshot')
          },
          {
            Header: 'Percy',
            accessor: row => getAccess(row, 'percy')
          },
          {
            Header: 'App Percy',
            accessor: row => getAccess(row, 'appPercy')
          },
          {
            Header: 'Observability',
            accessor: row => getAccess(row, 'testObservability')
          },
          {
            Header: 'Accessibility',
            accessor: row => getAccess(row, 'accessibilityTesting')
          },
          {
            Header: 'Test Management',
            accessor: row => getAccess(row, 'testManagement')
          },
          {
            Header: 'Code Quality Cloud',
            accessor: row => getAccess(row, 'codeQualityCloud')
          },
          {
            Header: 'Code Quality Self Managed',
            accessor: row => getAccess(row, 'codeQualitySelfManaged')
          },
          {
            Header: 'Low Code Automation',
            accessor: row => getAccess(row, 'lowCodeAutomation')
          },
          {
            Header: 'App Accessibility',
            accessor: row => getAccess(row, 'appAccessibilityTesting')
          },
          {
            Header: 'Automate Turboscale',
            accessor: row => getAccess(row, 'automateTurboscale')
          }
        ]
      },
      { Header: 'Last Login', accessor: 'lastLogin' },
      {
        Header: 'GeoLocation',
        accessor: row => getLocation(row.location)
      },
      {
        Header: 'Send To SFDC',
        disableFilter: true,
        disableSorting: true
      }
    ];

    const powerUsersColumn = [
      {
        Header: 'Sr. No',
        accessor: () => 'PENDING',
        disableSorting: true,
        disableFilter: true
      },
      { Header: 'Poduct', accessor: 'product' },
      { Header: 'User Id', accessor: 'user_id' },
      { Header: 'Name', accessor: 'full_name' },
      { Header: 'Email', accessor: 'email' },
      { Header: 'Total Duration (Sec)', accessor: 'total_duration' }
    ];

    this.state = {
      columns: columns,
      powerUsersColumn: powerUsersColumn,
      isLoaded: false,
      error: null,
      powerUsers: props.powerUsers
    };

    this.addRow = this.addRow.bind(this);
    this.processContactSync = this.processContactSync.bind(this);
  }

  addRow(newRow) {
    this.setState({
      data: [...this.state.rows, newRow]
    });
  }

  processContactSync(row) {
    sendContactToSf(row, this.context);
  }

  static styleRow(row) {
    if (row.unsubscribedFlag) {
      return { backgroundColor: '#ffaaaa' };
    }
    return {};
  }

  render() {
    return (
      <>
        <DenseTable>
          <CustomTable
            columns={
              this.state.powerUsers
                ? this.state.powerUsersColumn
                : this.state.columns
            }
            data={this.props.rows}
            styleRow={UserTable.styleRow}
            processContactSync={this.processContactSync}
          />
        </DenseTable>
      </>
    );
  }
}

UserTable.contextType = ModalContext;

class SubgroupInfoWidget extends React.Component {
  constructor(props) {
    super(props);
    if (this.props.powerUsers) {
      this.state = {
        from: this.props.powerUsers.timeRangeStart,
        to: this.props.powerUsers.timeRangeEnd
      };
    }

    this.handleChangeFrom = this.handleChangeFrom.bind(this);
    this.handleChangeTo = this.handleChangeTo.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  productUsageToAccessKeyMapper = {
    live: 'live',
    automate: 'automate',
    'app-live': 'appLive',
    'app-automate': 'appAutomate'
  };

  handleChangeFrom(event) {
    this.setState({ from: event.target.value });
  }

  handleChangeTo(event) {
    this.setState({ to: event.target.value });
  }

  handleSubmit(event) {
    const from = dayjs(this.state.from, 'YYYY-MM-DD').local();
    const to = dayjs(this.state.to, 'YYYY-MM-DD').local();

    if (!from.isValid()) {
      alert(`From: ${this.state.from} is not a valid date.`);
    } else if (!to.isValid()) {
      alert(`To: ${this.state.to} is not a valid date.`);
    } else {
      this.props.reload('powerUsers', this.state.from, this.state.to);
    }
    event.preventDefault();
  }

  render() {
    const { subgroupInfo, powerUsers } = this.props;
    if (!subgroupInfo) return <div>Loading Users Info Failed</div>;

    // preprocess powerUsers
    const userUsageMap = {};
    const maxUsers = {};
    powerUsers.rows.map(row => {
      const productKey = this.productUsageToAccessKeyMapper[row.product];
      userUsageMap[row.user_id] = userUsageMap[row.user_id] || {};
      userUsageMap[row.user_id][productKey] = row.total_duration;
      if (maxUsers[row.product]) {
        if (row.total_duration > maxUsers[row.product]['total_duration'])
          maxUsers[row.product] = row;
      } else {
        maxUsers[row.product] = row;
      }
    });

    const subgroupTables = values(
      mapValues(subgroupInfo, value => {
        const { userList } = value;
        Object.keys(userList).forEach(key => {
          const usage = userUsageMap[key] || {};
          userList[key]['usage'] = usage;
        });
        const userArray = values(userList);
        return (
          <React.Fragment key={value.subGroupName}>
            <h4>
              Subgroup: {value.subGroupName} ({value.subGroupId})
            </h4>
            <UserTable rows={userArray} powerUsers={false} />
          </React.Fragment>
        );
      })
    );
    return (
      <>
        <Col xs="12">
          <h2>Users Information</h2>
          <Form inline>
            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
              <Label>
                <b>Select Time Range</b>
              </Label>
            </FormGroup>
            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
              <Label> From: </Label>
              <Input
                type="date"
                value={this.state.from}
                onChange={this.handleChangeFrom}
              />
            </FormGroup>
            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
              <Label> To: </Label>
              <Input
                type="date"
                value={this.state.to}
                onChange={this.handleChangeTo}
              />
            </FormGroup>
            <Button onClick={this.handleSubmit}>Submit</Button>
          </Form>
          {powerUsers && (
            <React.Fragment key={'powerUser'}>
              <h4>Power users</h4>
              <UserTable rows={values(maxUsers)} powerUsers={true} />
            </React.Fragment>
          )}
          {subgroupTables}
          <Legend />
        </Col>
      </>
    );
  }
}

SubgroupInfoWidget.propTypes = {
  subgroupInfo: Proptype.object,
  powerUsers: Proptype.object,
  reload: Proptype.func
};

UserTable.propTypes = {
  rows: Proptype.array,
  powerUsers: Proptype.bool
};

export default SubgroupInfoWidget;
