import React from 'react';
import Proptype from 'prop-types';
import _baseTab from '../utils/_baseTab';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import { SimpleTooltip } from '../utils/simpleToolTip';
import config from '../../config';
import LeadInfoSubTab from './leadInfoSubTab';
import { timedFetch, recordTime } from '../utils/customFetch';
import AuthContext from '../context';

import * as Sentry from '@sentry/browser';

import { get } from 'lodash-es';

// Used for getting a stateful time recording callback
const [recordTimeCallback] = recordTime();

async function fetchData(name, baseUrl, id) {
  const url = `${baseUrl}${id}`;
  try {
    const requestObject = await timedFetch(name, id, recordTimeCallback, url, {
      credentials: 'include'
    });
    return await requestObject.json();
  } catch (error) {
    console.log(error);
    Sentry.withScope(scope => {
      scope.setExtras({ url, type: 'fetchData' });
      Sentry.captureException(error);
    });
    return null;
  }
}

const defaultleadInfo = {
  leadInfo: [],
  mqlEvents: [],
  templateHash: {},
  segmentHash: {},
  productInterest: {}
};

class MqlIntelligence extends _baseTab {
  constructor(props) {
    super(props);

    this.state = {
      inputValue: '',
      isLoaded: {
        leadInfo: false
      },
      loadedData: {
        leadInfo: defaultleadInfo
      }
    };
  }

  componentDidMount() {
    if (this.props.inputValue != null && this.props.inputValue != '') {
      this.startSearch(this.props.inputValue);
    } else {
      this.setState({
        isLoaded: {
          leadInfo: true
        }
      });
    }
  }

  fetchAndSet(baseUrl, id, objectName, path = null) {
    fetchData(objectName, baseUrl, id).then(object => {
      this.setState({
        isLoaded: {
          [objectName]: true
        },
        loadedData: {
          [objectName]: (path ? get(object, path) : object) || defaultleadInfo
        }
      });
    });
  }

  validateData = event => {
    let valid = true;
    const input = event.target.value;
    this.state.inputValue = input;
  };

  startSearch = inputValue => {
    this.fetchAndSet(config.urls.getLeadInfo, inputValue, 'leadInfo');
  };
  onSubmit = event => {
    this.setState({
      isLoaded: { leadInfo: false }
    });
    this.startSearch(this.state.inputValue);
    event.preventDefault();
  };

  render() {
    return (
      <>
        <h1> Enter details here</h1>
        <Form inline>
          <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
            <Label>
              <b style={{ fontSize: '0.85rem' }}>Enter Email / Domain: </b>
              <FontAwesomeIcon
                icon={faQuestion}
                style={{ color: 'blue' }}
                id="search-leads"
              />
              <SimpleTooltip placement="right" target="search-leads">
                abcd@testmail.com or testmail.com
              </SimpleTooltip>
            </Label>
          </FormGroup>
          <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
            <Input type="text" onChange={this.validateData} />
          </FormGroup>
          <Button type="submit" onClick={this.onSubmit}>
            Submit
          </Button>
        </Form>
        <LeadInfoSubTab
          isLoaded={this.state.isLoaded}
          loadedData={this.state.loadedData?.leadInfo}
        />
      </>
    );
  }
}

MqlIntelligence.contextType = AuthContext;
MqlIntelligence.propTypes = {
  inputValue: Proptype.string
};
export default MqlIntelligence;
