import * as Sentry from '@sentry/browser';
import config from '../../../../config';
import { postJson }  from '../../../utils/customFetch'

/**
 * A helper that tells the server to recompute the value.
 *
 * @param {Array.<string>} opps An array of opportunity ids to compute for
 * @returns {Promise} if success, gives list of errors, otherwises rejects with the errors
 */
export function recalculateNetAcv(opps) {
  return new Promise((resolve, reject) => {
    postJson(config.urls.netAcvCreateJob, { 'opps': opps })
      .then((create) => {
        if (!create.status) throw new Error(`Failure to start net acv job for opps: ${opps}.`)

        const intervalTimeMs = 6000;
        const retryCount = (25 + (opps.length * 25)) * 1000 / intervalTimeMs;

        const timerId = setInterval(async () => {
          if (retryCount <= 0) clearInterval(timerId);

          postJson(config.urls.netAcvCheckStatus, { 'job_id': create.job_id })
            .then((status) => {
              if (status.completed) {
                const errors = JSON.stringify(status.errors)
                if (status.success) resolve(errors); // The process can succeed with errors
                else reject(new Error(errors)) 
                clearInterval(timerId);
              }
            })
        }, intervalTimeMs);
      })
      .catch(err => {
        Sentry.configureScope((scope) => {
          scope.setExtra('hawkeye_type', 'recalculateNetAcv');
          scope.setExtra('opps', opps);
        });
        Sentry.captureException(err)
        reject(err)
      });
  });
}
