import React from 'react';
import Proptype from 'prop-types';
import { Table, Col } from 'reactstrap';
import { DenseTable } from '../utils/_styles';

class FailedChargesWidget extends React.Component {
  render() {
    const { failedCharges } = this.props;
    if (!failedCharges) return <div>Failed to load charges failed.</div>;

    const failedChargesRendered = failedCharges.map((failedCharge, i) => {
      return (
        <tr key={i}>
          <td>{failedCharge.failureType}</td>
          <td>
            {new Date(Date.parse(failedCharge.failedDate)).toDateString()}
          </td>
        </tr>
      );
    });
    return (
      <>
        <Col>
          <h2>Failed Charges</h2>
          <DenseTable>
            <Table striped bordered>
              <thead>
                <tr>
                  <th>Failure Type</th>
                  <th>Failed Date</th>
                </tr>
              </thead>
              <tbody>{failedChargesRendered}</tbody>
            </Table>
          </DenseTable>
        </Col>
      </>
    );
  }
}

FailedChargesWidget.propTypes = {
  failedCharges: Proptype.array
};

export default FailedChargesWidget;
