import config from '../../../../config';
import { postJson }  from '../../../utils/customFetch'
import * as Sentry from '@sentry/browser';


/** Get a download link for a dump for the given list of groups.
 * 
 * @param {string[]} groups A list of group ids for which to extract the data
 * @returns {Promise} that resolves to url path of dump download
 */
function getDownloadDump(groups) {
  Sentry.configureScope((scope) => {
    scope.setExtra('hawkeye_type', 'getDownloadDump');
    scope.setExtra('groups', groups);
  });
  return new Promise((resolve, reject) => {
    postJson(config.urls.dbDumpStartJob, { 'groups': groups })
      .then((createResult) => {
        const jobId = createResult.jobId

        const intervalTimeMs = 6000;
        let retryCount = (5 + (groups.length * 5)) * 1000 / intervalTimeMs;

        const timerId = setInterval(async () => {
          if (retryCount <= 0) {
            clearInterval(timerId);
            reject('Dump Request Timed out.')
          }

          postJson(config.urls.dbDumpCheckJobStatus, { 'jobId': jobId })
            .then((status) => {
              retryCount -= 1;
              if (status.completed) {
                if (status.error !== null) {
                  clearInterval(timerId);
                  Sentry.captureException(new Error(status.error));
                  reject(status.error); // The process can succeed with errors
                } else {
                  resolve(`${config.urls.dbDumpDownloadLink}/${jobId}`);
                  clearInterval(timerId);
                }
              }
            })
            .catch((response) => {
              clearInterval(timerId);
              const errMessage = response.text();
              Sentry.captureException(new Error(errMessage));
              reject(`HTTP ${response.statue} - ${errMessage}`);
            });
        }, intervalTimeMs);
      })
      .catch(err => {
        Sentry.captureException(err)
        reject(err)
      });
  });
}


export { getDownloadDump };
