import React, { useEffect, useState, useRef, useContext } from 'react';
import styled from 'styled-components';
import { Col, Row, Button, Container, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ModalContext } from '../../../utils/alertModal';

import { faDownload } from '@fortawesome/free-solid-svg-icons';

import config from '../../../../config';
import NetAcvTable from './tableCode';
import { fetchData, fetchWithRollback } from '../../../utils/customFetch';
import { recalculateNetAcv } from './netAcvCalcService';
import { getDownloadDump } from './downloadDump';
import { SetClipBoardOnClick } from '../../../utils/clipboard';
import { getColumnFilter, TriStateFilter } from '../../../utils/_filters';
import {
  getOrders,
  oppDetails,
  formatYesNo,
  isYesNo,
  ButtonWithAsyncJob,
} from './helpers';

const Styles = styled.div`
  padding: 1rem;
  .current_order {
    color: darkgreen;
  }
  .previous_order {
    color: darkblue;
  }

  .download_link a {
    color: black;
    transition: all 0.21s;

    &:hover {
      color: white;
      transition: all 0.1s;
    }
  }

  div {
    span.boolCell {
      padding: 8px;
      width: inherit;
      margin-left: -8px;
      position: absolute;
      top: 0px;
      bottom: 1px;
      border-right: thin black solid;

      &.no {
        background: pink;
      }
      &.yes {
        background: lightgreen;
      }
    }
  }
  .table {
    display: inline-block;
    border-spacing: 0;
    border: 1px solid black;

    .th,
    .td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`;

function netAcvCheckBox({ oppId, initState, addMessage }) {
  const [selected, setSelected] = useState(initState);

  const onBoxClick = () => {
    setSelected(!selected);
    fetchWithRollback(
      'POST',
      config.urls.updateNetAcvEdits,
      () => {
        addMessage('ERROR', `Failed to edit calcIsCorrect for ${oppId}`, 6000);
        setSelected(selected);
      },
      async () => {
        addMessage('INFO', `Updated checkbox for ${oppId}`);
      },
      { oppId, field: 'calcIsCorrect', newData: !selected }
    );
  };

  return (
    <span onClick={onBoxClick} style={{ float: 'left' }}>
      {selected === false ? '☒' : '☑'}
    </span>
  );
}

function netAcvEditBox({ oppId, initState, addMessage }) {
  const [onServer, setOnServer] = useState(initState);
  const [text, setText] = useState(initState);

  const onEnter = (e) => {
    if (e.which !== 13) return;

    fetchWithRollback(
      'POST',
      config.urls.updateNetAcvEdits,
      () => {
        addMessage('ERROR', `Failed to edit notes for ${oppId}`, 6000);
        setText(onServer);
      },
      async () => {
        setOnServer(text);
        addMessage('INFO', `Updated comments for ${oppId}`);
      },
      { oppId, field: 'notes', newData: text }
    );
  };

  return (
    <div className="form-group">
      <textarea
        className="form-input"
        value={text}
        onKeyPress={onEnter}
        onChange={(e) => setText(e.target.value)}
        placeholder="Enter Comments(Press Enter to Submit)"
      />
    </div>
  );
}

/**
 * This function contains the logic to get the table data
 *
 * @returns {React.Component} The rendered table
 */
function NetAcvTableComponent() {
  const [data, setData] = useState([]);
  const filteredOppIds = useRef();
  if (!filteredOppIds.current) filteredOppIds.current = [];

  const addMessage = useContext(ModalContext);

  const getData = async () => {
    const result = await fetchData('netAcvData', config.urls.getNetAcv);
    setData(result);
  };
  useEffect(() => {
    getData();
  }, []);

  const columnDef = [
    {
      Header: 'Sr. No.',
      accessor: (row, index) => index,
      width: '50',
      disableFilters: true,
    },
    {
      Header: 'Group Id',
      // eslint-disable-next-line react/display-name
      Cell: (tableProps) => (
        <SetClipBoardOnClick value={tableProps.cell.row.original.group_id} />
      ),
      accessor: 'group_id',
      Filter: getColumnFilter('4rem'),
      width: '100',
    },
    {
      Header: 'Opportunity',
      Cell: (tableProps) => oppDetails(tableProps.cell.row.original)[0],
      accessor: (row) => oppDetails(row)[1],
      Filter: getColumnFilter('140px'),
      width: '240',
    },
    {
      Header: 'Baseline',
      accessor: (row) =>
        isYesNo(row.is_renewal) === 'Yes' ? row.baseline_acv : '',
      width: '70',
      disableFilters: true,
    },
    {
      Header: 'Net Acv',
      accessor: 'net_acv',
      width: '70',
      disableFilters: true,
    },
    {
      Header: 'Sfdc IArr',
      accessor: 'sfdc_iarr',
      width: '70',
      disableFilters: true,
    },
    {
      Header: 'Close Date',
      accessor: (row) => row.opp_details.close_date.split('T')[0],
      Filter: getColumnFilter('80px'),
      width: '120',
    },
    {
      Header: 'Orders',
      width: '200',
      accessor: getOrders,
    },
    {
      Header: 'isRecon',
      Cell: (tableProps) =>
        formatYesNo(
          tableProps.cell.row.original.opp_details.is_recon ? 'Yes' : 'No'
        ),
      accessor: (row) => (row.opp_details.is_recon ? 'Yes' : 'No'),
      Filter: TriStateFilter,
      width: '70',
    },
    {
      Header: 'isRenewal',
      Filter: TriStateFilter,
      width: '70',
      disableFilter: true,
      accessor: (row) => isYesNo(row.is_renewal),
      Cell: (tableProps) =>
        formatYesNo(isYesNo(tableProps.cell.row.original.is_renewal)),
    },
    {
      Header: 'isManuallyUpdated',
      Cell: (tableProps) =>
        formatYesNo(
          tableProps.cell.row.original.opp_details.is_manually_updated ? 'Yes' : 'No'
        ),
      accessor: (row) => (row.opp_details.is_manually_updated ? 'Yes' : 'No'),
      Filter: TriStateFilter,
      width: '120',
    },
    {
      Header: 'isM->A',
      disableFilter: true,
      Filter: TriStateFilter,
      width: '70',
      accessor: (row) => isYesNo(row.is_monthly_to_annual),
      Cell: (tableProps) =>
        formatYesNo(isYesNo(tableProps.cell.row.original.is_monthly_to_annual)),
    },
    {
      Header: '6monthRule',
      Filter: TriStateFilter,
      width: '80',
      Cell: (tableProps) =>
        formatYesNo(
          isYesNo(tableProps.cell.row.original.six_month_rule_applied)
        ),
      accessor: (row) => isYesNo(row.six_month_rule_applied),
    },
    {
      Header: 'isConsolidated',
      width: '95',
      Filter: TriStateFilter,
      Cell: (tableProps) =>
        formatYesNo(isYesNo(tableProps.cell.row.original.was_consolidated)),
      accessor: (row) => isYesNo(row.was_consolidated),
    },
    {
      Header: 'mismatchWithSfdc',
      width: '115',
      Filter: TriStateFilter,
      Cell: (tableProps) =>
        formatYesNo(
          Math.abs(
            tableProps.cell.row.original.sfdc_iarr -
              tableProps.cell.row.original.net_acv
          ) > 2
            ? 'Yes'
            : 'No'
        ),
      accessor: (row) =>
        Math.abs(row.sfdc_iarr - row.net_acv) > 2 ? 'Yes' : 'No',
    },
    {
      Header: 'RailsCalcIsCorrect',
      width: '115',
      Filter: TriStateFilter,
      Cell: (tableProps) =>
        netAcvCheckBox({
          oppId: tableProps.cell.row.original.opportunity_id,
          initState: tableProps.cell.row.original.edits_calcIsCorrect,
          addMessage,
        }),
      accessor: (row) => isYesNo(row.edits_calcIsCorrect),
    },
    {
      Header: 'Notes',
      width: '200',
      disableFilter: true,
      Cell: (tableProps) =>
        netAcvEditBox({
          oppId: tableProps.cell.row.original.opportunity_id,
          initState: tableProps.cell.row.original.edits_notes,
          addMessage,
        }),
      accessor: 'edits_notesk',
    },
  ];

  const itemSize = (index) => {
    let length = (data[index].all_orders_req_details.length / 2) * 29;
    if (isYesNo(data[index].is_recon) === 'No') {
      length = Math.max(28 * 3, length);
    } else if (isYesNo(data[index].is_recon) === 'Yes') {
      length = Math.max(28 * 2, length);
    }
    return length;
  };

  const recalculateAllVisble = () => {
    return new Promise((resolve, reject) => {
      const opps = filteredOppIds.current;
      recalculateNetAcv(opps)
        .then(async (errors) => {
          console.log(`Calculation Errors: ${errors}`);
          const results = await getData();
          resolve(results);
        })
        .catch((err) => {
          console.log(`Failed in recalculation for ${opps}`);
          console.log(err);
          reject(err);
        });
    });
  };

  const [downloadUrl, setDownloadUrl] = useState('');
  const filteredGroupIds = useRef();
  if (!filteredGroupIds.current) filteredGroupIds.current = [];

  const downloadDBVisible = () => {
    return new Promise((resolve, reject) => {
      setDownloadUrl('');
      const groups = filteredGroupIds.current.map((x) => x.toString());
      getDownloadDump(groups)
        .then((url) => {
          setDownloadUrl(url);
          resolve(url);
        })
        .catch((err) => reject(err));
    });
  };

  const completionTarget = (
    <span className="download_link">
      <a
        href={downloadUrl}
        target="_blank"
        rel="noopener noreferrer"
        onClick={(e) => e.stopPropagation()}
      >
        Download Link
      </a>
    </span>
  );

  return (
    <Styles>
      <Container fluid>
        <Row className="mb-2">
          <Col sm={9}>
            <ButtonWithAsyncJob color="primary" job={getData}>
              Reload Data(No Calc)
            </ButtonWithAsyncJob>
            <ButtonWithAsyncJob color="danger" job={recalculateAllVisble}>
              Recalculate the Filtered Rows
            </ButtonWithAsyncJob>
            <ButtonWithAsyncJob
              color="info"
              id="download_button"
              job={downloadDBVisible}
              completionTarget={completionTarget}
            >
              Download All Visible Rows
            </ButtonWithAsyncJob>
          </Col>
        </Row>
      </Container>
      <NetAcvTable
        columns={columnDef}
        data={data}
        itemSize={itemSize}
        estimatedItemSize="84"
        filteredOppIds={filteredOppIds}
        filteredGroupIds={filteredGroupIds}
      />
    </Styles>
  );
}

export default NetAcvTableComponent;
