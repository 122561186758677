import React from 'react';
import { Col, Row, Container } from 'reactstrap';
const styles = {
  square: {
    background: '#ff8080',
    width:'20px',
    height:'20px',
  },
  container: {
    width: '250px',
    height: '50px',
    border: '1px solid black',
    padding: '15px 12px',
    margin: '0px 0px 10px 0px'
  }
}

export class Legend extends React.Component {
  render() {
    return (
      <div className="columns" style={styles.container}>
        <Container fluid>
          <Row xs="1" marginBottom="10px">
            <Col xs={{ size: 'auto', offset: 0 }}><div style={styles.square}></div></Col>
            <Col xs={{ size: 'auto', offset: 0 }}><p>Unsubscribed marketing</p></Col>
          </Row>
        </Container>
      </div>
    );
  }
}

