import React from 'react';
import Proptype from 'prop-types';
import { values } from 'lodash-es';
import { Col, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';

import CustomTable from '../utils/customTable';
import { DenseTable } from '../utils/_styles';

dayjs.extend(customParseFormat);
dayjs.extend(utc);

class BuildMetricsTable extends React.Component {
  constructor(props) {
    super(props);

    const columns = [
      {
        Header: 'Sr. No',
        accessor: () => 'PENDING',
        disableSorting: true,
        disableFilter: true
      },
      { Header: 'Project Name', accessor: 'name' },
      { Header: 'Subgroup ID', accessor: 'subgroupId' },
      { Header: 'Subgroup Name', accessor: 'subgroupName' },
      { Header: 'No. Of Builds', accessor: 'noOfBuilds' },
      { Header: 'Builds Passed', accessor: 'buildsPassed' },
      { Header: 'Avg. Tests per Build', accessor: 'avgTestsPerBuild' },
      { Header: 'Avg. Duration(mins)', accessor: 'avgTestDuration' },
      {
        Header: 'Avg. Peak Parallels per Build',
        accessor: 'avgPeakParallelsPerBuild'
      },
      {
        Header: 'Max Peak Parallels per build',
        accessor: 'maxPeakParallelsPerBuild'
      },
      {
        Header: 'Avg. Avg. Parallels per Build',
        accessor: 'avgAvgParallelsPerBuild'
      },
      {
        Header: 'Max Avg. Parallels per build',
        accessor: 'maxAvgParallelsPerBuild'
      },
      { Header: 'Build Time (Seconds)', accessor: 'buildTimesSeconds' },
      { Header: 'Build Time (Minutes)', accessor: 'buildTimesMinutes' },
      { Header: 'Build Time (Hours)', accessor: 'buildTimesHours' },
      { Header: 'Average Build Time (hours)', accessor: 'avgBuildTimesHours' },
      {
        Header: 'Developer Wait Time per build',
        accessor: 'developerWaitTimePerBuild'
      },
      {
        Header: 'Developer Wait Time per day',
        accessor: 'developerWaitTimePerDay'
      },
      { Header: 'Total Tests', accessor: 'totalTests' },
      { Header: 'Total Succesful Tests', accessor: 'totalSuccessfulTests' },
      { Header: 'Total Error Tests', accessor: 'totalErrorTests' },
      { Header: 'Total Timeout Tests', accessor: 'totalTimeoutTests' },
      { Header: 'Total Failure Tests', accessor: 'totalFailureTests' }
    ];

    this.state = {
      columns: columns,
      isLoaded: false,
      error: null
    };
  }

  render() {
    return (
      <>
        <CustomTable columns={this.state.columns} data={this.props.rows} />
      </>
    );
  }
}

class BuildMetricsWidget extends React.Component {
  constructor(props) {
    super(props);
    if (this.props.buildMetrics) {
      this.state = {
        from: this.props.buildMetrics.timeRangeStart,
        to: this.props.buildMetrics.timeRangeEnd
      };
    }

    this.handleChangeFrom = this.handleChangeFrom.bind(this);
    this.handleChangeTo = this.handleChangeTo.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChangeFrom(event) {
    this.setState({ from: event.target.value });
  }

  handleChangeTo(event) {
    this.setState({ to: event.target.value });
  }

  handleSubmit(event) {
    const from = dayjs(this.state.from, 'YYYY-MM-DD').local();
    const to = dayjs(this.state.to, 'YYYY-MM-DD').local();

    if (!from.isValid()) {
      alert(`From: ${this.state.from} is not a valid date.`);
    } else if (!to.isValid()) {
      alert(`To: ${this.state.to} is not a valid date.`);
    } else {
      this.props.reload('buildMetrics', this.state.from, this.state.to);
    }
    event.preventDefault();
  }

  render() {
    const { buildMetrics } = this.props;
    if (!buildMetrics) return <div>Loading Build Metrics Failed</div>;

    return (
      <>
        <Col xs="12">
          <h2>Build Metrics</h2>
          <Form inline>
            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
              <Label>
                <b>Select Time Range</b>
              </Label>
            </FormGroup>
            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
              <Label> From: </Label>
              <Input
                type="date"
                value={this.state.from}
                onChange={this.handleChangeFrom}
              />
            </FormGroup>
            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
              <Label> To: </Label>
              <Input
                type="date"
                value={this.state.to}
                onChange={this.handleChangeTo}
              />
            </FormGroup>
            <Button onClick={this.handleSubmit}>Submit</Button>
          </Form>
          <DenseTable>
            <BuildMetricsTable rows={values(buildMetrics.projects)} />
          </DenseTable>
        </Col>
      </>
    );
  }
}

BuildMetricsWidget.propTypes = {
  buildMetrics: Proptype.object,
  reload: Proptype.func
};

BuildMetricsTable.propTypes = {
  rows: Proptype.array
};

export default BuildMetricsWidget;
