import React from 'react';
import { Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';

/** Show a spinner if isLoaded == false
 * show the component otherwise
 *
 * @param {boolean} isLoaded a state/prop that tells if loaded or not
 * @param {object} Component The component to show if loaded.
 * @param {string} placeholderType The type of placeholder
 * @returns {object} Component with loading animation
 */
export function loadingSoon(isLoaded, Component, placeholderType = 'center') {
  return (
    <>
      {isLoaded ? (
        <>{Component}</>
      ) : (
        <Col className={`${placeholderType}-parent loadingPlaceholder`}>
          <div className={`${placeholderType}-child placeHolderChild`}>
            <h5 className="loadingText">Loading&nbsp;</h5>
            <FontAwesomeIcon className="fa-spin" icon={faCog} />
          </div>
        </Col>
      )}
    </>
  );
}

/** Show a spinner
 *
 * @param {boolean} showText show loading or not
 * @param {string} placeholderType The type of placeholder
 * @returns {object} Component with loading animation
 */
export function showSpinner(showText = true, placeholderType = 'spinner') {
  return (
    <div className={`${placeholderType}-parent loadingPlaceholder`}>
      <div className={`${placeholderType}-child placeHolderChild`}>
        {showText && <span className="loadingText">Loading&nbsp;</span>}
        <FontAwesomeIcon className="fa-spin" icon={faCog} />
      </div>
    </div>
  );
}

/** Show a ProgressBar until loading is complete.
 * Note: The progress bar will only be hidden if loadingPercent == 100
 *
 * @param {number} loadingPercent Number between 0 and 100(incl.) showing the load progress.
 * @param {React.Component} message The message to show while loading.
 * @param {boolean} error set to true for hiding the Loadin text and show an erro
 * @returns {object} Component with loading animation
 */
export function loadingSoonProgress(loadingPercent, message, error = false) {
  return (
    <>
      {loadingPercent < 100 && (
        <Col className={'progress-parent loadingPlaceholder'}>
          <div className={'progress-child placeHolderChild'}>
            <div>
              {!error && <h1>Loading {loadingPercent.toFixed(2)}%</h1>}
              <br />
              <h3 className="loadingText">{message}</h3>
              <br />
              <div className="meter animate">
                <span style={{ width: `${loadingPercent}%` }}>
                  <span></span>
                </span>
              </div>
            </div>
          </div>
        </Col>
      )}
    </>
  );
}
