import React from 'react';
import PropTypes from 'prop-types';
import './customTable.css';
import { useTable, useSortBy, useFilters, useColumnOrder } from 'react-table';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSort,
  faAngleDown,
  faAngleUp,
  faWindowClose,
  faSync
} from '@fortawesome/free-solid-svg-icons';

import { CollapsibleColumnFilter } from './_filters';
import ErrorBoundary from './errorBoundary';

function getEmailHyperLink(email) {
  return (
    <a
      href={`https://www.browserstack.com/admin/user_stats?q=${email}&column_selected=Email&commit=Go`}
      target="_blank"
    >
      {email}
    </a>
  );
}

const SyncSpin = styled.div`
  text-align: center;
  svg {
    cursor: pointer;
  }
`;

/** The Custom Table that is based on react-table.
 *
 * @param {object} props The Properties for the functional Object
 * @returns {React.Component} Functional React Component - CustomTable
 */
function CustomTable({
  columns,
  data,
  removeRow,
  styleRow,
  additionalClasses,
  showTemplateModal,
  processContactSync
}) {
  const defaultColumn = React.useMemo(
    () => ({
      Filter: CollapsibleColumnFilter // Let's set up our default Filter UI
    }),
    []
  );

  const { getTableProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      defaultColumn
    },
    useColumnOrder,
    useFilters,
    useSortBy
  );

  return (
    <ErrorBoundary>
      <table
        {...getTableProps()}
        className={`table-bordered table table-condensed ${additionalClasses}`}
      >
        <thead>
          {headerGroups.map((headerGroup, i) => (
            <tr key={i} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, i2) => (
                <th key={i2} {...column.getHeaderProps()}>
                  <div {...column.getSortByToggleProps()}>
                    {column.render('Header')}
                    <span>
                      {column.canSort ? (
                        column.isSorted ? (
                          column.isSortedDesc ? (
                            <FontAwesomeIcon icon={faAngleDown} />
                          ) : (
                            <FontAwesomeIcon icon={faAngleUp} />
                          )
                        ) : (
                          <FontAwesomeIcon icon={faSort} />
                        )
                      ) : (
                        ''
                      )}
                    </span>
                  </div>
                  <div style={{ float: 'right' }}>
                    {column.canFilter ? column.render('Filter') : null}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          <>
            {rows.map(
              (row, rowIndex) =>
                prepareRow(row) || (
                  <tr
                    {...row.getRowProps()}
                    style={styleRow ? styleRow(row.original) : {}}
                  >
                    {row.cells.map((cell, i) => {
                      if (cell.column.Header === 'Sr. No') {
                        // For Row number
                        return (
                          <td key={i} {...cell.getCellProps()}>
                            {rowIndex + 1}
                          </td>
                        );
                      }
                      if (removeRow && cell.column.Header === 'Remove Group') {
                        // For Row delete
                        return (
                          <td key={i} {...cell.getCellProps()}>
                            <div
                              onClick={() => removeRow(row.original.groupId)}
                            >
                              <FontAwesomeIcon icon={faWindowClose} />
                            </div>
                          </td>
                        );
                      }
                      if (
                        showTemplateModal &&
                        cell.column.Header === 'Email Template'
                      ) {
                        let columnStyles = {
                          cursor: 'pointer'
                        };
                        return (
                          <td key={i} {...cell.getCellProps()}>
                            <div
                              className="template-link"
                              style={columnStyles}
                              onClick={() => {
                                let email = row.cells.filter(col => {
                                  return col.column.Header === 'Lead';
                                });
                                showTemplateModal(email[0].value);
                              }}
                            >
                              {cell.render('Cell')}
                            </div>
                          </td>
                        );
                      }
                      if (Array.isArray(cell.value)) {
                        return (
                          <td
                            key={`${i}-${cell.column.Header}`}
                            {...cell.getCellProps()}
                          >
                            {cell.value.map(line => {
                              return (
                                <div>
                                  {<b> - </b>}
                                  {line}
                                  <br />
                                </div>
                              );
                            })}
                          </td>
                        );
                      }
                      if (
                        (cell.column.Header === 'Status' &&
                          ['Automate/AppAutomate MQL', 'Not yet MQL'].includes(
                            cell.value
                          )) ||
                        (cell.column.Header === 'Lead Score' &&
                          cell.value === 'Paying Customer')
                      ) {
                        let statusStyle = {
                          backgroundColor: '#22bb33',
                          color: 'white',
                          padding: '3px',
                          borderRadius: '3px'
                        };
                        if (cell.value === 'Not yet MQL') {
                          statusStyle.backgroundColor = '#bb2124';
                        } else if (cell.column.Header === 'Lead Score') {
                          statusStyle.backgroundColor = '#f0ad4e';
                        }
                        return (
                          <td
                            key={`${i}-${cell.column.Header}`}
                            {...cell.getCellProps()}
                          >
                            <span style={statusStyle}>
                              {cell.render('Cell')}
                            </span>
                          </td>
                        );
                      }
                      if (cell.column.Header === 'Send To SFDC') {
                        return (
                          <td
                            key={`${i}-${cell.column.Header}`}
                            {...cell.getCellProps()}
                          >
                            {
                              <SyncSpin>
                                <FontAwesomeIcon
                                  icon={faSync}
                                  size="lg"
                                  onClick={event => {
                                    let element = event.target.parentElement;
                                    processContactSync(row.original);
                                    element.classList.add('fa-spin');

                                    setTimeout(function() {
                                      element.classList.remove('fa-spin');
                                    }, 3000);
                                  }}
                                />
                              </SyncSpin>
                            }
                          </td>
                        );
                      }
                      return (
                        <td
                          key={`${i}-${cell.column.Header}`}
                          {...cell.getCellProps()}
                        >
                          {cell.column.Header === 'Email'
                            ? getEmailHyperLink(cell.value)
                            : cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                )
            )}
          </>
        </tbody>
      </table>
    </ErrorBoundary>
  );
}

CustomTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  removeRow: PropTypes.func,
  styleRow: PropTypes.func,
  additionalClasses: PropTypes.string
};

export default CustomTable;
