import React from "react";
import _baseTab from "../utils/_baseTab";
import {
  Button,
  Col,
  Row,
  ListGroup,
  ListGroupItem,
  Label,
  Input,
  FormText,
  Card,
  CardTitle,
  CardText,
  CardSubtitle,
} from "reactstrap";
import config from "../../config";
import { ModalContext } from "../utils/alertModal";
import { postJson, fetchData } from "../utils/customFetch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

/**
 * @param row row data from table
 * @param addMessage function for displaying toasts
 */
async function disconnectSFAuthenticator(
  addMessage,
  enableComponents,
  enableSuccessfulIcon,
  enableErrorIcon
) {
  addMessage("SFDC Auth", "Disconnecting SFDC Authenticator", 2000);
  const payload = {};
  postJson(config.urls.disconnectSFDCAuth, payload)
    .then((result) => {
      if (result && result.success) {
        addMessage("Success", `${result.message}`, 3000);
        enableSuccessfulIcon();
      } else {
        addMessage("Error", `${result.message}`, 3000);
        enableErrorIcon();
      }
      enableComponents();
    })
    .catch((error) => {
      addMessage("Error", `${error}`, 2000);
      enableComponents();
      enableErrorIcon();
    });
}

async function fetchUserEmailPreferences() {
  let result = await fetchData(
    "user_email_preferences",
    config.urls.getEmailPreferences,
    {}
  );
  if (result && result.success) {
    return result.data;
  }
}

async function saveUserEmailPreferences(preferenceData, addMessage) {
  const payload = { preferenceData: preferenceData };
  postJson(config.urls.saveEmailPreferences, payload)
    .then((result) => {
      if (result && result.success) {
        addMessage(
          "Success",
          `Your Preferences have been saved successfully.`,
          3000
        );
      } else {
        addMessage("Error", result.message, 3000);
      }
    })
    .catch((error) => {
      addMessage(
        "Error",
        `Something Went Wrong while Saving Preferences.`,
        3000
      );
    });
}

class Settings extends _baseTab {
  constructor(props) {
    super(props);
    this.state = {
      disabled: false,
      successful: false,
      error: false,
      preferencesData: {},
      mockData: {},
      epOngoingTransition: false,
    };
    this.enableComponents = this.enableComponents.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.enableErrorIcon = this.enableErrorIcon.bind(this);
    this.enableSuccessfulIcon = this.enableSuccessfulIcon.bind(this);
  }

  componentDidMount() {
    this.formatPreferenceState();
  }

  async formatPreferenceState() {
    let resp = await fetchUserEmailPreferences();
    this.setState({
      preferencesData: resp,
    });
  }

  enableComponents = () => {
    this.setState({ disabled: false });
  };

  enableSuccessfulIcon = () => {
    this.setState({ successful: true });
    this.setState({ error: false });
  };

  enableErrorIcon = () => {
    this.setState({ successful: false });
    this.setState({ error: true });
  };

  handleClick = (event) => {
    if (this.state.disabled) {
      return;
    }
    this.setState({ disabled: true });
    disconnectSFAuthenticator(
      this.context,
      this.enableComponents,
      this.enableSuccessfulIcon,
      this.enableErrorIcon
    );
    event.preventDefault();
  };

  handleEmailPreferenceToggle = (event) => {
    if (event.target.id && this.state.preferencesData[event.target.id]) {
      this.state.preferencesData[event.target.id].access = !this.state
        .preferencesData[event.target.id].access;
      this.forceUpdate();
    }
  };

  saveEmailPreferences = (event) => {
    this.setState({ epOngoingTransition: true });
    saveUserEmailPreferences(this.state.preferencesData, this.context);
  };

  render() {
    const isSuccessful = this.state.successful;
    const isError = this.state.error;
    return (
      <div style={{ margin: "25px" }}>
        <Row>
          <h1 style={{ margin: "5px" }}>Settings</h1>
          <br />
        </Row>
        <Row>
          <Col>
            <Card body>
              <CardTitle tag="h5">Disconnect SFDC Authenticator</CardTitle>

              <CardSubtitle className="mb-2 text-muted" tag="h6">
                Disconnect Salesforce 2FA, in case you're switching your mobile.
                Please note that you will have to set up a new device while
                logging in.
              </CardSubtitle>
              <CardText>
                <Button
                  disabled={this.state.disabled}
                  onClick={this.handleClick}
                  color="primary"
                  style={{ marginLeft: "10px" }}
                >
                  Disconnect
                </Button>
                {isSuccessful && (
                  <FontAwesomeIcon
                    icon={faCheck}
                    size="3x"
                    style={{ marginLeft: "10px" }}
                    color="green"
                    display="none"
                  />
                )}
                {isError && (
                  <FontAwesomeIcon
                    icon={faTimes}
                    size="3x"
                    style={{ marginLeft: "10px", marginTop: "2px" }}
                    color="red"
                    display="none"
                  />
                )}
              </CardText>
            </Card>
          </Col>
          <Col>
            <Card body>
              <CardTitle tag="h5">Weekly Account Summary Preferences</CardTitle>
              <CardSubtitle className="mb-2 text-muted" tag="h6">
                Customize your weekly account information
              </CardSubtitle>
              <CardText>
                <ListGroup>
                  {Object.entries(this.state.preferencesData).map(
                    ([preferenceKey, preferenceData]) => (
                      <ListGroupItem style={{ border: "0px" }}>
                        <Input
                          id={preferenceKey}
                          type="checkbox"
                          checked={
                            this.state.preferencesData[preferenceKey].access
                          }
                          onChange={this.handleEmailPreferenceToggle}
                        />

                        <Label check>
                          {
                            config.emailPreferenceHelperText[preferenceKey]
                              .label
                          }
                        </Label>

                        <FormText>
                          {
                            config.emailPreferenceHelperText[preferenceKey]
                              .helper
                          }
                        </FormText>
                      </ListGroupItem>
                    )
                  )}
                </ListGroup>
              </CardText>
              <Button
                disabled={this.state.emailPreferenceSaveState}
                onClick={this.saveEmailPreferences}
                color="primary"
                style={{ marginTop: "10px", marginRight: "0px" }}
              >
                Save Preferences
              </Button>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

Settings.contextType = ModalContext;
export default Settings;
