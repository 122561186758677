import React from 'react';
import { Col, Row, Container } from 'reactstrap';
import _baseTab from '../utils/_baseTab';
import SubscriptionWidget from '../groupsWidgets/subscriptionWidget';
import SubgroupInfoWidget from '../groupsWidgets/subgroupsInfoWidget';
import UserLocationWidget from '../groupsWidgets/userLocationWidget';
import HealthScoreMetricsWidget from '../groupsWidgets/healthScoreMetricsWidget';
import OrderHistoryWidget from '../groupsWidgets/ordersWidget';
import FailedChargesWidget from '../groupsWidgets/failedChargesWidget';
import LinksWidget from '../groupsWidgets/linksWidget';
import { loadingSoon } from '../utils/loadingSoon';

export class GroupDetailsSubTab extends _baseTab {
  render() {
    const { isLoaded, loadedData, groupId } = this.props;
    return (
      <>
        <Container fluid>
          <Row>
            <Col>
              {loadingSoon(
                isLoaded?.groupDetails,
                <SubscriptionWidget
                  subscriptionStatuses={
                    loadedData?.groupDetails?.subscriptionStatuses || {}
                  }
                />
              )}
            </Col>
            <Col>
              {loadingSoon(
                isLoaded?.subgroupInfo,
                <SubgroupInfoWidget subgroupInfo={loadedData?.subgroupInfo} />
              )}
              {loadingSoon(
                isLoaded?.userLocation,
                <UserLocationWidget userLocation={loadedData?.userLocation} />
              )}
              {loadingSoon(
                isLoaded?.userLocation,
                <HealthScoreMetricsWidget healthScoreInfo={loadedData?.healthScore} />
              )}
            </Col>
            <Col sm="2">{<LinksWidget groupId={groupId} />}</Col>
          </Row>
        </Container>
        <hr />
        <Container fluid>
          <Row>
            {loadingSoon(
              isLoaded?.orderHistory,
              <OrderHistoryWidget
                orderHistory={loadedData?.orderHistory}
                groupId={loadedData?.groupId}
              />
            )}
            {loadingSoon(
              isLoaded?.failedCharges,
              <FailedChargesWidget failedCharges={loadedData?.failedCharges} />
            )}
          </Row>
        </Container>
      </>
    );
  }
}
