import React from 'react';
import { Table } from 'reactstrap';
import { DenseTable } from '../../../utils/_styles';

const CsvTable = ({ data, rowKey, colKey }) => {
  const headerRow = data.slice(0, 1)[0];
  const tableRows = data.slice(1, data.length);

  const renderTableHeader = row => {
    if (row && row.map) {
      return (
        <thead>
          <tr>
            {row.map((column, i) => (
              <th key={`header-${i}`}>{column}</th>
            ))}
          </tr>
        </thead>
      );
    }
  };

  const renderTableBody = rows => {
    if (rows && rows.map) {
      return (
        <tbody>
          {rows.map((row, rowIdx) => (
            <tr key={rowIdx}>
              {row.map &&
                row.map((column, colIdx) => (
                  <td
                    key={
                      typeof rowKey === 'function'
                        ? colKey(row, colIdx, rowIdx)
                        : column[colKey]
                    }
                  >
                    {column}
                  </td>
                ))}
            </tr>
          ))}
        </tbody>
      );
    }
  };

  return (
    <DenseTable>
      <Table striped bordered>
        {renderTableHeader(headerRow)}
        {renderTableBody(tableRows)}
      </Table>
    </DenseTable>
  );
};

CsvTable.defaultProps = {
  data: [],
  rowKey: (row, rowIdx) => `row-${rowIdx}`,
  colKey: (col, colIdx, rowIdx) => `col-${colIdx}`
};

export default CsvTable;
