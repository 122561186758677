import React from 'react';
import Proptype from 'prop-types';
import { mapValues, values } from 'lodash-es';
import { Table, Col } from 'reactstrap';
import { DenseTable } from '../utils/_styles';

class SubgroupInfoWidget extends React.Component {
  render() {
    const { subgroupInfo } = this.props;
    if (!subgroupInfo) return <div>Loading Subgroup Info Failed</div>;
    const subgroupData = values(
      mapValues(subgroupInfo, value => {
        return (
          <React.Fragment key={value.subGroupName}>
            <tr>
              <td key="name">{value.subGroupName}</td>
              <td key="automateParallelTestsAllowed">
                {value.automateParallelTestsAllowed}
              </td>
              <td key="appAutomateParallelTestsAllowed">
                {value.appAutomateParallelTestsAllowed}
              </td>
              <td key="liveParallelTestsAllowed">
                {value.liveParallelTestsAllowed}
              </td>
              <td key="appLiveParallelTestsAllowed">
                {value.appLiveParallelTestsAllowed}
              </td>
              <td key="noOfUsers">{value.noOfUsers}</td>
            </tr>
          </React.Fragment>
        );
      })
    );
    return (
      <>
        <Col>
          <h2>{'\u00A0'}</h2>
          {/* For aligning to the subscription status */}
          <h2>Subgroups</h2>
          <DenseTable>
            <Table striped bordered>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Automate Parallel Tests</th>
                  <th>App Automate Parallel Tests</th>
                  <th>Live licenses allocated</th>
                  <th>App Live licenses allocated</th>
                  <th>Users Added</th>
                </tr>
              </thead>
              <tbody>{subgroupData}</tbody>
            </Table>
          </DenseTable>
          <span>
            Note: `--` denotes all users who are not assigned Sub Groups
          </span>
        </Col>
      </>
    );
  }
}

SubgroupInfoWidget.propTypes = {
  subgroupInfo: Proptype.object
};

export default SubgroupInfoWidget;
