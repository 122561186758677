import React from 'react';
import Proptype from 'prop-types';
import { values } from 'lodash-es';
import { Col } from 'reactstrap';

import { DenseTable } from '../utils/_styles';
import CustomTable from '../utils/customTable';

class LeadTable extends React.Component {
  constructor(props) {
    super(props);

    const columns = [
      {
        Header: 'Sr. No',
        accessor: () => 'PENDING',
        disableSorting: true,
        disableFilter: true
      },
      { Header: 'email_domain', accessor: 'email_domain' },
      { Header: 'email', accessor: 'email' },
      { Header: 'group_id', accessor: 'group_id' },
      { Header: 'user_id', accessor: 'user_id' },
      { Header: 'event_date', accessor: 'event_date.value' },
      { Header: 'event_name', accessor: 'event_name' },
      { Header: 'event_data', accessor: 'event_data' },
      { Header: 'current_group_status', accessor: 'current_group_status' },
      { Header: 'event_score', accessor: 'event_score' },
    ];

    this.state = {
      columns: columns,
      isLoaded: false,
      error: null
    };

    this.addRow = this.addRow.bind(this);
  }

  addRow(newRow) {
    this.setState({
      data: [...this.state.rows, newRow]
    });
  }

  static styleRow(row) {
    if (row.unsubscribedFlag) {
      return { backgroundColor: '#ffaaaa' };
    }
    return {};
  }

  render() {
    return (
      <>
        <DenseTable>
          <CustomTable
            columns={this.state.columns}
            data={this.props.rows}
            styleRow={LeadTable.styleRow}
          />
        </DenseTable>
      </>
    );
  }
}

class MqlEventsWidget extends React.Component {
  render() {
    const { leadInfo, scrollRef } = this.props;
    if (!leadInfo) return <div>Loading Lead Info Failed</div>;
    const leadArray = values(leadInfo);

    return (
      <>
        <Col xs="12">
          <h4 ref={scrollRef}>MQL Events</h4>
          <LeadTable rows={leadArray} />
        </Col>
      </>
    );
  }
}

MqlEventsWidget.propTypes = {
  leadInfo: Proptype.array,
  scrollRef: Proptype.object
};

LeadTable.propTypes = {
  rows: Proptype.array
};

export default MqlEventsWidget;
