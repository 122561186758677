// eslint-disable-next-line no-unused-vars
import { useState } from 'react';
import update from 'immutability-helper';

/** React Hook based tabs
 *
 * @returns {object} Object with result functions
 */
function useTabs({ initialTabs, initialActiveTab }) {
  const [tabs, setTab] = useState(initialTabs);
  const [activeTab, updateActiveTab] = useState(initialActiveTab);

  const addTab = (name, content) => {
    if (!(name in tabs)) {
      setTab(update(tabs, { $merge: { [name]: content } }));
    }
  };
  const removeTab = name => {
    if (name in tabs && Object.keys(tabs).length > 1) {
      // Only allow deleting if more than one tab
      setTab(update(tabs, { $unset: [name] }));
      if (name === activeTab) {
        updateActiveTab(Object.keys(tabs).find(curr => curr !== name)); // set the first tab in the list as parent
      }
      return true;
    }
    return false;
  };
  const getTabs = () => tabs;
  const setActiveTab = name => {
    updateActiveTab(name);
  };
  const getActiveTab = () => tabs[activeTab];
  const getActiveTabTitle = () => activeTab;
  const getAllTabTitles = () => Object.keys(tabs);

  return {
    addTab,
    removeTab,
    getTabs,
    setActiveTab,
    getActiveTabTitle,
    getActiveTab,
    getAllTabTitles
  };
}

export default useTabs;
