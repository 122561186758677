import styled from 'styled-components';

const TableStyles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;
      background: white;

      :last-child {
        border-right: 0;
      }
    }
  }
`;

const GlobalStyles = styled.div`
  font-size: 0.7rem;
  .fa-spin {
    animation: fa-spin 2s infinite linear;
    -webkit-animation: fa-spin 2s infinite linear;
  }

  h1 {
    font-size: 1.6rem;
  }
  h2 {
    font-size: 1.5rem;
  }
  h3 {
    font-size: 1.4rem;
  }
  h4 {
    font-size: 1.3rem;
  }
  h5 {
    font-size: 1.2rem;
  }
  h6 {
    font-size: 1.1rem;
  }

  .loadingPlaceholder {
    // Common Styling for all loading placeholders
    background: #eeeeee;
    border-radius: 7px;
    .placeHolderChild {
      color: grey;
      font-size: 3em;
      .loadingText {
        margin-top: 0px;
        margin-bottom: 0px;
      }
      .fa-spin {
        animation: fa-spin 2s infinite linear;
        -webkit-animation: fa-spin 2s infinite linear;
      }
    }

    // Specific Styling for row-level spinner
    &.spinner-parent {
      margin: 0.75rem;
      .spinner-child {
        font-size: 0.8rem;
        padding: 0.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    // Specific Styling for Centering-in-a-box placeholder
    &.center-parent {
      margin: 0.75rem;
      height: 40vh;
      .center-child {
        height: 100%;
        padding: 0.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    // Specific Styling for Title placeholder
    &.title-parent {
      margin: -0.1rem 0rem 0.5rem 0rem;
      padding: 4px 15px;
      .title-child {
        display: flex;
        justify-content: start;
        align-items: center;
      }
    }

    // Specific Styling for progressBar placeholder
    &.progress-parent {
      height: 80vh;
      .progress-child {
        height: 100%;
        padding: 0.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: Montserrat;

        h1 {
          font-size: 2rem;
        }
        h3 {
          font-size: 1.7rem;
        }

        > div {
          display: flex;
          flex-direction: column;
          width: 70%;
          align-items: center;
        }
      }

      .meter {
        width: 100%;
        height: 50px; /* Can be anything */
        position: relative;
        background: #ccc;
        border-radius: 25px;
        padding: 10px 10px;
        box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);

        span {
          display: block;
          height: calc(100% - 20px);
          border-radius: 20px;
          background-color: #777;
          position: absolute;
          overflow: hidden;
          transition: width 0.01s linear;

          span {
            animation: move 2s linear infinite;
          }
          &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background-image: linear-gradient(
              -45deg,
              rgba(255, 255, 255, 0.2) 25%,
              transparent 25%,
              transparent 50%,
              rgba(255, 255, 255, 0.2) 50%,
              rgba(255, 255, 255, 0.2) 75%,
              transparent 75%,
              transparent
            );
            z-index: 1;
            background-size: 50px 50px;
            animation: move 2s linear infinite;
            border-radius: 20px;
            overflow: hidden;
          }
          @keyframes move {
            0% {
              background-position: 0 0;
            }
            100% {
              background-position: 50px 50px;
            }
          }
        }
      }
    }
  }
`;

const DenseTable = styled.div`
  table {
    th {
      padding: 5px;
    }
    td {
      padding: 5px;
      hr {
        margin: 5px 0px;
        padding: 0;
      }
    }
  }
`;

const AlertModalStyle = styled.div`
  position: fixed;
  bottom: 2rem;
  right: 2rem;

  width: 20rem;

  display: flex;
  flex-direction: column;

  border: thin solid lightgrey;
  border-radius: 7px;

  &.empty {
    height: 5rem;
  }

  div {
    padding: 0.5rem;
  }
  .title {
    border-radius: 7px 7px 0px 0px;
    height: 1.5rem;
    background-color: #eee;
    padding: 0.2rem 0.8rem;
    border-bottom: thin solid #ccc;

    .hide-button {
      float: right;
      transition: color;

      &:hover {
        color: crimson;
      }
    }
  }
  .content {
    border-radius: 0px 0px 7px 7px;
    flex-grow: 1;
    background-color: #f7f7f7;
  }
`;

// This is a very screwed up way to freeze headers, it will destroy the alignment
// of the headers on some zoom levels.
// Anyone is welcome to fix this, but take note that the component using this will need
// to be modified as well.
const FreezeHeader = styled.div`
  .scrollingtable {
    box-sizing: border-box;
    display: inline-block;
    min-width: 100%;
    /*if you want a fixed width, set it here, else set to auto*/
    overflow-y: hidden;
    min-height: 80%;

    * {
      box-sizing: border-box;
    }

    > div {
      position: relative;
      border-top: thin solid grey;
      height: 100%;
      padding-top: 3.4rem; /*this determines column header height*/
    }

    > div:before {
      top: 0;
      background: white; /*header row background color*/
    }

    > div:before,
    > div > div:after {
      content: '';
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 50%;
      left: 0;
    }

    > div > div {
      min-height: /*0*/ 43px; /*if using % height, make this large enough to fit scrollbar arrows*/
      max-height: 100%;
      overflow: /*scroll*/ auto; /*set to auto if using fixed or % width; else scroll*/
      border: 1px solid black; /*border around table body*/

      &:after {
        background: white;
      } /*match page background color*/

      > table {
        width: 100%;
        border-spacing: 0;
        margin-top: calc(-3rem); /*inverse of column header height*/
        margin-right: 17px; /*uncomment if using % width*/

        > * > tr > * {
          padding: 0;
        }

        > thead {
          vertical-align: bottom;
          white-space: nowrap;
          text-align: center;

          > tr > * {
            > div {
              // display: inline-block;
              padding: 0 6px 0 6px; /*header cell padding*/
            }

            > div[label]:before,
            > div > div:first-child,
            + :before {
              position: absolute;
              top: 0;
              white-space: pre-wrap;
              color: black; /*header row font color*/
            }
            > div[label]:before,
            > div[label]:after {
              content: attr(label);
            }
            + :before {
              content: '';
              display: block;
              min-height: 3.4rem; /*match column header height*/
              padding-top: 1px;
              border-left: 2px solid black; /*borders between header cells*/
              margin-left: -1px;
            }
          }
          > tr > :first-child:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 3.4rem; /*match column header height*/
            border-left: 1px solid black; /*leftmost header border*/
          }
        }

        tbody {
          vertical-align: top;

          &:last-of-type > tr:last-child > * {
            border-bottom: none;
          }

          > tr {
            background: white;

            > * + * {
              border-left: 1px solid gainsboro;
            } /*borders between body cells*/
            > * {
              border-bottom: 1px solid gainsboro;
              padding: 0 6px 0 6px;
              height: 3.4rem; /*match column header height*/
            }
          }
          > tr:after {
            content: '';
            display: table-cell;
            position: relative;
            padding: 0;
            border-top: 1px solid black;
            top: -1px; /*inverse of border width*/
          }
        }
      }
    }

    .scrollbarhead {
      &:before {
        margin-left: 1px;
      }
    }
  }
`;

const SubtleInputBoxStyling = styled.span`
  input {
    font-size: 1em;
    padding: 5px;
    display: inline-block;
    width: 7rem;
    border: none;
  }
  .searchGroup {
    padding: 3px 3px 0px 3px;
    border-bottom: 1px solid #757575;

    &:focus-within {
      box-shadow: 0px 2px 3px #888888;
      input {
        outline: none;
        border: none;
      }
    }
  }
`;

const tieredPricingAccordionStyling = {
  display: 'block',
  'font-weight': '400',
  'text-decoration': 'none',
  color: '#333333',
  position: 'relative',
  border: '1px solid white',
  padding: '10px',
  background: '#6c757d',
  color: 'white',
  'margin-bottom': '10px'
};

export {
  SubtleInputBoxStyling,
  TableStyles,
  GlobalStyles,
  FreezeHeader,
  DenseTable,
  AlertModalStyle,
  tieredPricingAccordionStyling
};
