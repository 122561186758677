import Proptype from 'prop-types';
import React from 'react';
import { Container } from 'reactstrap';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import * as Sentry from '@sentry/browser';
import { get, merge, mergeWith, isArray } from 'lodash-es';
import ErrorBoundary from '../utils/errorBoundary';

import MainTab from '../utils/mainTabs';
import config from '../../config';
import _baseTab from '../utils/_baseTab';
import constants from '../../constants';

import { UsersSubTab } from './usersSubTab';
import { GroupDetailsSubTab } from './groupDetailsSubTab';
import { BuildMetricsSubTab } from './buildMetricSubTab';
import { loadingSoon } from '../utils/loadingSoon';
import { timedFetch, recordTime } from '../utils/customFetch';
import { sendEvent } from '../utils/eds';
import AuthContext from '../context';

dayjs.extend(customParseFormat);
dayjs.extend(utc);

// Used for getting a stateful time recording callback
const [recordTimeCallback, getTimeData] = recordTime();

/** Get the data at while using the Cookies
 *
 * @param {string} name Type of object
 * @param {string} baseUrl the baseUrl for the object
 * @param {string} id the object id
 * @returns {Promise} the resolves to the fetched data or rejects with an error
 */
async function fetchData(name, baseUrl, id, retry = 0) {
  const url = `${baseUrl}${id}`;
  try {
    const requestObject = await timedFetch(name, id, recordTimeCallback, url, {
      credentials: 'include'
    });
    return await requestObject.json();
  } catch (error) {
    if (retry >= 3) {
      Sentry.withScope(scope => {
        scope.setExtras({
          url,
          type: 'fetchData'
        });
        Sentry.captureException(error);
      });
      return null;
    } else {
      await new Promise(r => setTimeout(r, 1000));
      return await fetchData(name, baseUrl, id, retry + 1);
    }
  }
}

class GroupsTab extends _baseTab {
  constructor(props) {
    super(props);

    this.state = {
      groupId: props.groupId,
      isLoaded: {
        groupDetails: false,
        subgroupInfo: false,
        powerUsers: false,
        userLocation: false,
        orderHistory: false,
        failedCharges: false,
        buildMetrics: false,
        healthScore: false
      },
      loadedData: {
        groupDetails: {
          domainName: null,
          subscriptionStatuses: null
        },
        subgroupInfo: null,
        userLocation: null,
        orderHistory: null,
        failedCharges: null,
        buildMetrics: null,
        powerUsers: null,
        healthScore: null
      },
      initialActiveTab: 'Group Details',
      loadPerfData: {
        initialTime: performance.now(),
        fullyLoaded: false
      }
    };

    this.reloadMetrics = (objectName, from, to) => {
      this.setState(
        merge(this.state, {
          isLoaded: {
            [objectName]: false
          }
        })
      );
      this.fetchAndSet(
        this.endpoints[objectName],
        `${this.props.groupId}?from=${from}&to=${to}`,
        objectName
      );
    };

    this.endpoints = {
      powerUsers: config.urls.getPowerUsers,
      buildMetrics: config.urls.getBuildMetrics
    };
  }

  // TODO Add Failure cleanup
  fetchAndSet(baseUrl, id, objectName, path = null) {
    fetchData(objectName, baseUrl, id).then(object => {
      // mergeWith will assign the array in object instead of merging arrays in this.state
      this.setState(
        mergeWith(
          this.state,
          {
            isLoaded: {
              [objectName]: true
            },
            loadedData: {
              [objectName]: path ? get(object, path) : object
            }
          },
          (_, b) => (isArray(b) ? b : undefined)
        )
      );
    });
  }

  async componentDidMount() {
    await fetchData(
      'groupInfo',
      config.urls.getGroupInfo,
      this.props.groupId
    ).then(groupInfo => {
      this.setState(
        merge(this.state, {
          isLoaded: {
            groupDetails: true,
            subgroupInfo: true,
            userLocation: true,
            orderHistory: true,
            failedCharges: true
          },
          loadedData: groupInfo
        })
      );
    });

    await fetchData(
      'healthScore',
      config.urls.getHealthScore,
      this.props.groupId
    ).then(healthScore => {
      this.setState(
        merge(this.state, {
          isLoaded: {
            healthScore: true
          },
          loadedData: healthScore
        })
      );
    });
  }

  handleTabChange = tab => {
    const today = dayjs().utc();
    const to = today.format('YYYY-MM-DD');
    if (
      tab == constants.tabs.group_summary.build_metrics &&
      !this.state?.isLoaded?.buildMetrics
    ) {
      const from = today.subtract(1, 'day').format('YYYY-MM-DD');
      this.fetchAndSet(
        config.urls.getBuildMetrics,
        `${this.props.groupId}?from=${from}&to=${to}`,
        'buildMetrics'
      );
    } else if (
      tab == constants.tabs.group_summary.users_info &&
      !this.state?.isLoaded?.powerUsers
    ) {
      const from = today.subtract(30, 'day').format('YYYY-MM-DD');
      this.fetchAndSet(
        config.urls.getPowerUsers,
        `${this.props.groupId}?from=${from}&to=${to}`,
        'powerUsers'
      );
    }
  };

  componentDidUpdate() {
    if (!this.state.loadPerfData.fullyLoaded) {
      const timeTaken = performance.now() - this.state.loadPerfData.initialTime;
      const extraData = {
        totalTimeTaken: timeTaken,
        requestType: 'groupDetailsTab',
        user_id: this.context.userID,
        subRequests: getTimeData()
      };
      sendEvent('performance', extraData);

      this.setState(merge(this.state, { loadPerfData: { fullyLoaded: true } }));
    }
  }

  render() {
    const tabs = {
      [constants.tabs.group_summary.group_details]: (
        <GroupDetailsSubTab
          isLoaded={this.state.isLoaded}
          loadedData={this.state.loadedData}
          groupId={this.state.groupId}
        />
      ),
      [constants.tabs.group_summary.users_info]: (
        <UsersSubTab
          isLoaded={this.state.isLoaded}
          loadedData={this.state.loadedData}
          reload={this.reloadMetrics}
        />
      ),
      [constants.tabs.group_summary.build_metrics]: (
        <BuildMetricsSubTab
          isLoaded={this.state.isLoaded}
          loadedData={this.state.loadedData}
          reload={this.reloadMetrics}
        />
      )
    };

    return (
      <ErrorBoundary>
        <Container fluid className="mt-2">
          {loadingSoon(
            this?.state?.isLoaded?.groupDetails,
            <Container fluid>
              <h1>
                Group:{' '}
                {this?.state?.loadedData?.groupDetails?.domainName || '-'}(
                {this.props.groupId})
              </h1>
              <span>
                <b>Billing Details: </b>{' '}
                {this?.state?.loadedData?.groupDetails?.billTo || '-'}
              </span>
            </Container>,
            3,
            'title'
          )}
          <MainTab
            tabs={tabs}
            initialActiveTab={this.state.initialActiveTab}
            onTabChange={this.handleTabChange}
          />
        </Container>
      </ErrorBoundary>
    );
  }
}

GroupsTab.contextType = AuthContext;
GroupsTab.propTypes = {
  groupId: Proptype.string
};

export default GroupsTab;
