import React from 'react';
import _baseTab from '../utils/_baseTab';
import BuildMetricsWidget from '../groupsWidgets/buildMetricsWidget';
import { loadingSoon } from '../utils/loadingSoon';

export class BuildMetricsSubTab extends _baseTab {
  render() {
    const { isLoaded, loadedData, reload } = this.props;
    return (
      <>
        {loadingSoon(
          isLoaded?.buildMetrics,
          <BuildMetricsWidget
            buildMetrics={loadedData?.buildMetrics}
            reload={reload}
          />
        )}
      </>
    );
  }
}
