import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import { AlertModalStyle } from './_styles';
import { useArray, useDelay } from './hooks';

/** A hook for abstracting away the modal Alert functionality
 *
 * @returns {Array} [addMessage] a function that can push a message to the stack.
 */
export function useModalAlert() {
  const messagesArray = useArray([]);

  const addMessage = (type, content, timeout = 3000) => {
    const tempArray = new Uint32Array(1);
    window.crypto.getRandomValues(tempArray);

    const uniqueId = tempArray[0];
    messagesArray.add({
      id: uniqueId,
      type,
      content
    });
    setTimeout(() => messagesArray.removeId(uniqueId), timeout);
  };
  return [messagesArray.value, addMessage, messagesArray.clear];
}

/** A simple modal that can be used to inform the user of results of
 * UI interactions. This is a display component. Handling of messages is done
 * by useModal
 *
 * @returns {React.Component} Visual Component.
 */
export function AlertModal({ messages, clearMessages }) {
  const [delayedCall, abortCall] = useDelay();
  const [toShow, setToShow] = useState(false);

  const renderedMessages = messages.map(message => {
    return (
      <div key={message.id}>
        {message.type}
        <br />
        {message.content}
      </div>
    );
  });

  // Basically start a timer to hide the alert box as soon as messages is empty
  // and stop it as soon as messages is not empty.
  if (messages.length === 0) {
    delayedCall(() => {
      setToShow(false);
    }, 1500);
  } else {
    abortCall();
    if (!toShow) setToShow(true);
  }

  if (!toShow && messages.length === 0) {
    return null;
  }

  return (
    <AlertModalStyle className={messages.length === 0 ? 'empty' : ''}>
      <div className="title">
        Messages
        <span
          className="hide-button"
          onClick={() => {
            setToShow(false);
            clearMessages();
          }}
        >
          {' '}
          {/* Currently clears the messageArray */}
          <FontAwesomeIcon icon={faTimesCircle} />
        </span>
      </div>
      <div className="content">
        {messages.length > 0 ? renderedMessages : <>No Alerts for now.</>}
      </div>
    </AlertModalStyle>
  );
}

AlertModal.propTypes = {
  messages: PropTypes.array,
  clearMessages: PropTypes.func
};

export const ModalContext = React.createContext();
