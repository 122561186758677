// eslint-disable-next-line no-unused-vars
import React, { useCallback, useEffect, useState, useRef } from 'react';

/**
 * @typedef {object} useArrayHook
 * @property {Array} value Current Array Value
 * @property {Function} setValue useState
 * @property {Function} add (action => setValue(value => [...value, action] ))
 * @property {Function} clear a Function that empties the array () => []
 * @property {Function} removeId a Function that removes item from array by Id
 * @property {Function} removeIndex => ( removes item by index
 */

/** React hook to simplify the usage of arrays
 * from https://www.npmjs.com/package/@marcusfrancis/usearray
 *
 * @param {*} initialState - the initial state, unlike with classes, the state doesn’t have to be an object.
 * @returns {useArrayHook} A custom typedef.
 */
function useArray(initialState) {
  const [value, setValue] = useState(initialState);

  return {
    value,
    setValue,
    add: useCallback(a => setValue(v => [...v, a])),
    clear: useCallback(() => setValue(() => [])),
    removeId: useCallback(id =>
      setValue(arr => arr.filter(v => v && v.id !== id))
    ),
    removeIndex: useCallback(
      index =>
        setValue(v => {
          const copy = v.slice();
          copy.splice(index, 1);
          return copy;
        }),
      []
    )
  };
}

/** A hook that can be used to trigger a cancellabl, delayed callback.
 * delayCall(callbackFn, delay)
 * abortCall
 *
 * @returns {Function[]} [delayCall, abortCall]
 */
function useDelay() {
  const isTicking = useRef(false);
  const [timerRef, setTimerRef] = useState(0);

  const delayedCall = (callback, delay) => {
    if (!isTicking.current) {
      setTimerRef(
        setTimeout(() => {
          callback();
          isTicking.current = false;
        }, delay)
      );
      isTicking.current = true;
    }
  };

  const abortCall = () => {
    if (isTicking.current) {
      clearTimeout(timerRef);
      isTicking.current = false;
    }
  };

  return [delayedCall, abortCall];
}

/** A helper hook that can be used for getting the most current value
 * of the state carrier.
 *
 * @param {any} initialValue The initial Value for the state.
 * @returns {object} triplet of (state, ref, setFn)
 */
function useRefState(initialValue) {
  const [state, setState] = useState(initialValue);
  const stateRef = useRef(state);
  useEffect(() => {
    stateRef.current = state;
  }, [state]);
  return [state, stateRef, setState];
}

/**
 * React hook that returns the window size
 *
 * @returns {object} size, has width and height as keys
 */
function useWindowSize() {
  const isClient = typeof window === 'object';

  const getSize = () => {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined
    };
  };

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return false;
    }
    const handleResize = () => {
      setWindowSize(getSize());
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
}

export { useArray, useDelay, useRefState, useWindowSize };
