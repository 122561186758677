/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import matchSorter from 'match-sorter';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSearch,
  faToggleOff,
  faToggleOn
} from '@fortawesome/free-solid-svg-icons';

import { SubtleInputBoxStyling } from './_styles';

/** Define a default UI for filtering
 *
 * @param {object} props React Props
 * @returns {React.Component} Default filter UI.
 */
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter }
}) {
  const count = preFilteredRows.length;

  return (
    <input
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}

class SubtleColumnFilter extends React.Component {
  render() {
    return (
      <SubtleInputBoxStyling>
        <span style={{ float: 'left' }}>
          <div className="searchGroup">
            <FontAwesomeIcon icon={faSearch} />
            <input
              value={this.props.column.filterValue || ''}
              onChange={e => {
                this.props.column.setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
              }}
              placeholder={`Search ${this.props.column.preFilteredRows.length} records...`}
              style={{ width: '6rem' }}
            />
          </div>
        </span>
      </SubtleInputBoxStyling>
    );
  }
}

/**
 * A TriState Filter for the flag fields
 *
 * @param {object} Props props
 * @returns {object} Rendered component
 */
function TriStateFilter({ column }) {
  const [state, setState] = useState(undefined);
  return (
    <span style={{ float: 'left' }}>
      <div className="searchGroup">
        <br />
        Filter:
        <span
          style={{ fontSize: '1rem', cursor: 'pointer' }}
          onClick={e => {
            let newState;
            switch (state) {
              case undefined:
                newState = 'Yes';
                break;
              case 'Yes':
                newState = 'No';
                break;
              case 'No':
                newState = undefined;
                break;
              default:
                break;
            }
            setState(newState);
            column.setFilter(newState); // Set undefined to remove the filter entirely
            e.preventDefault();
          }}
        >
          {state === undefined ? '☐' : state === 'Yes' ? '☑' : '☒'}
        </span>
      </div>
    </span>
  );
}

/** Creates a specific width SubtleColumnFilter
 *
 * @param {number} width Width of the input box(excluding the icon)
 * @returns {React.Component} SubtleColumnfilter Component function.
 */
function getColumnFilter(width) {
  return function WideSubtleColumnFilter({ column }) {
    return (
      <SubtleInputBoxStyling>
        <span style={{ float: 'left' }}>
          <div className="searchGroup">
            <FontAwesomeIcon icon={faSearch} />
            <input
              value={column.filterValue || ''}
              onChange={e => {
                column.setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
              }}
              placeholder={`Search ${column.preFilteredRows.length} records...`}
              style={{ width: width }}
            />
          </div>
        </span>
      </SubtleInputBoxStyling>
    );
  };
}

class CollapsibleColumnFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = { collapsed: true };
    this.toggle = () => this.setState({ collapsed: !this.state.collapsed });
  }

  render() {
    return (
      <>
        <span style={{ float: 'right' }}>
          <FontAwesomeIcon icon={faSearch} />
          {this.state.collapsed ? (
            <FontAwesomeIcon icon={faToggleOff} onClick={this.toggle} />
          ) : (
            <>
              <FontAwesomeIcon icon={faToggleOn} onClick={this.toggle} />
              <input
                value={this.props.column.filterValue || ''}
                onChange={e => {
                  this.props.column.setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
                }}
                placeholder={`Search ${this.props.column.preFilteredRows.length} records...`}
                style={{ width: '6rem' }}
              />
            </>
          )}
        </span>
      </>
    );
  }
}

/** This is a custom filter UI for selecting a unique option from a list.
 *
 * @param {object} props props
 * @returns {React.Component} Default filter UI.
 */
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id }
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const filterOptions = new Set();
    preFilteredRows.forEach(row => {
      filterOptions.add(row.values[id]);
    });
    return [...filterOptions.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={e => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

/** This is a custom filter UI that uses a slider to set the
 * filter value between a column's min and max values
 *
 * @param {object} props props
 * @returns {React.Component} Default filter UI.
 */
function SliderColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id }
}) {
  // Calculate the min and max
  // using the preFilteredRows

  const [min, max] = React.useMemo(() => {
    let rowMin = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let rowMax = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach(row => {
      rowMin = Math.min(row.values[id], rowMin);
      rowMax = Math.max(row.values[id], rowMax);
    });
    return [rowMin, rowMax];
  }, [id, preFilteredRows]);

  return (
    <>
      <input
        type="range"
        min={min}
        max={max}
        value={filterValue || min}
        onChange={e => {
          setFilter(parseInt(e.target.value, 10));
        }}
      />
      <button onClick={() => setFilter(undefined)}>Off</button>
    </>
  );
}

/** This is a custom UI for our 'between' or number range filter.
 * It uses two number boxes and filters rows to ones that have values between the two
 *
 * @param {object} props props
 * @returns {React.Component} Default filter UI.
 */
function NumberRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id }
}) {
  const [min, max] = React.useMemo(() => {
    let rowMin = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let rowMax = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach(row => {
      rowMin = Math.min(row.values[id], rowMin);
      rowMax = Math.max(row.values[id], rowMax);
    });
    return [rowMin, rowMax];
  }, [id, preFilteredRows]);

  return (
    <div
      style={{
        display: 'flex'
      }}
    >
      <input
        value={filterValue[0] || ''}
        type="number"
        onChange={e => {
          const val = e.target.value;
          setFilter((old = []) => [
            val ? parseInt(val, 10) : undefined,
            old[1]
          ]);
        }}
        placeholder={`Min (${min})`}
        style={{
          width: '70px',
          marginRight: '0.5rem'
        }}
      />
      to
      <input
        value={filterValue[1] || ''}
        type="number"
        onChange={e => {
          const val = e.target.value;
          setFilter((old = []) => [
            old[0],
            val ? parseInt(val, 10) : undefined
          ]);
        }}
        placeholder={`Max (${max})`}
        style={{
          width: '70px',
          marginLeft: '0.5rem'
        }}
      />
    </div>
  );
}

/** Define custom logic for filtering.
 *
 * @param {object} rows rows
 * @param {number} id id for the row
 * @param {number} filterValue The value selected in the UI
 * @returns {boolean} The result of the Comparison.
 */
function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [row => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val;

/** Custom logic for filtering.
 * Greater than filter
 *
 * @param {object} rows rows
 * @param {number} id id for the row
 * @param {number} filterValue The value selected in the UI
 * @returns {boolean} The result of the Comparison.
 */
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter(row => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = val => typeof val !== 'number';

export {
  DefaultColumnFilter,
  SelectColumnFilter,
  SliderColumnFilter,
  NumberRangeColumnFilter,
  fuzzyTextFilterFn,
  filterGreaterThan,
  CollapsibleColumnFilter,
  SubtleColumnFilter,
  getColumnFilter,
  TriStateFilter
};
