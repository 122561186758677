import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTable, useSortBy, useFilters, useColumnOrder } from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSort,
  faAngleDown,
  faAngleUp,
  faWindowClose
} from '@fortawesome/free-solid-svg-icons';

import ErrorBoundary from '../errorBoundary';
import { SubtleColumnFilter } from '../_filters';
import { FreezeHeader } from '../_styles';
import { loadingSoonProgress } from '../loadingSoon';

const WideHeader = styled.div``;

/** Render table header
 *
 * @param {object} headerGroups The header groups given by react table
 * @returns {object} React JSX
 */
function renderTableHead(headerGroups) {
  const sortButton = column => {
    let iconName = faSort; // Default icon, for unsorted case.
    if (column.isSorted) {
      if (column.isSortedDesc) iconName = faAngleDown;
      else iconName = faAngleUp;
    }
    return (
      <>
        {' '}
        <FontAwesomeIcon icon={iconName} {...column.getSortByToggleProps()} />
      </>
    );
  };

  return (
    <thead>
      {headerGroups.map((headerGroup, i) => (
        <tr key={i} {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((column, i2) => (
            <th key={i2} {...column.getHeaderProps()}>
              <div>
                {/* The following div is repeated as the CSS requires a duplicate tp act as a frozen header. */}
                {[0, 1].map(dd => {
                  return (
                    <div key={dd}>
                      {column.render('Header')}
                      {column.canSort && sortButton(column)}
                      <br />
                      {column.canFilter ? column.render('Filter') : null}
                    </div>
                  );
                })}
              </div>
            </th>
          ))}
          <th className="scrollbarhead"></th>
        </tr>
      ))}
    </thead>
  );
}

/** A Custom Table with Frozen Headers that is based on react-table.
 *
 * @param {object} props The Properties for the functional Object
 * @returns {React.Component} Functional React Component - FrozenHeadTable
 */
function FrozenHeadTable({
  columns,
  data,
  removeRow,
  styleRow,
  additionalClasses,
  maxHeight
}) {
  const defaultColumn = React.useMemo(
    () => ({
      Filter: SubtleColumnFilter // Let's set up our default Filter UI
    }),
    []
  );

  const { getTableProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      defaultColumn
    },
    useColumnOrder,
    useFilters,
    useSortBy
  );

  return (
    <ErrorBoundary>
      <WideHeader>
        <FreezeHeader>
          <div className="scrollingtable" style={{ height: maxHeight }}>
            <div>
              <div>
                <table
                  {...getTableProps()}
                  className={`table-bordered table table-condensed ${additionalClasses}`}
                >
                  {renderTableHead(headerGroups)}
                  <tbody>
                    {rows.map((row, rowIndex) => {
                      // if (!row.isLoaded) {
                      //   console.log('testing')
                      //   return loadingSoonProgress(40, 'Test' )
                      // }

                      const prepCell = cell => {
                        if (cell.column.Header == 'Sr. No') return rowIndex + 1;
                        else if (removeRow && cell.column.Header == 'Remove') {
                          return (
                            <div
                              onClick={() => removeRow(row.original.groupId)}
                            >
                              <FontAwesomeIcon icon={faWindowClose} />
                            </div>
                          );
                        } else return cell.render('Cell');
                      };

                      return (
                        prepareRow(row) || (
                          <tr
                            {...row.getRowProps()}
                            style={styleRow ? styleRow(row.original) : {}}
                          >
                            {row.cells.map((cell, i) => {
                              return (
                                <td
                                  key={`${i}-${cell.column.Header}`}
                                  {...cell.getCellProps()}
                                >
                                  {prepCell(cell)}
                                </td>
                              );
                            })}
                          </tr>
                        )
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </FreezeHeader>
      </WideHeader>
    </ErrorBoundary>
  );
}

FrozenHeadTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  removeRow: PropTypes.func,
  styleRow: PropTypes.func,
  additionalClasses: PropTypes.string,
  maxHeight: PropTypes.string
};

export { FrozenHeadTable };
