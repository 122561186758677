import React, { useState } from 'react';
import Proptype from 'prop-types';
import { Table, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareSquare } from '@fortawesome/free-solid-svg-icons';
import { DenseTable } from '../utils/_styles';
import { fetchData } from '../utils/customFetch';
import config from '../../config';
import * as Sentry from '@sentry/browser';

class OrderHistoryWidget extends React.Component {
  state = { invoiceURLS: null };

  async fetchAndSet(baseUrl, groupId) {
    fetchData('order_invoice_urls', `${baseUrl}${groupId}/invoices/`)
      .then(data => {
        if (data && !data.error) {
          this.setState({
            invoiceURLS: data
          });
        }
      })
      .catch(err => {
        console.log(err);
        Sentry.captureException(err);
      });
  }

  componentDidMount() {
    if (this?.props?.groupId != null) {
      this.fetchAndSet(config.urls.getGroupInvoices, this.props.groupId);
    }
  }

  render() {
    const { orderHistory } = this.props;
    const { invoiceURLS } = this.state;

    if (!orderHistory) return <div>Loading Order failed.</div>;
    const orderHistoryRendered = orderHistory.map(order => {
      let invoice = '';
      if (invoiceURLS != null && invoiceURLS[(order?.orderId)] != null) {
        invoice = (
          <a
            href={invoiceURLS[order.orderId]}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faShareSquare} />
          </a>
        );
      }
      return (
        <tr
          key={`${order.orderId} - ${order.activationDate} - ${order.orderType} - ${order.mrr}`}
        >
          <td>{order.activationDate}</td>
          <td>{order.orderId}</td>
          <td>{order.pricingVersion}</td>
          <td>{order.planName}</td>
          <td>{order.orderType}</td>
          <td>{order.termLength}</td>
          <td>{order.paymentMode}</td>
          <td>{order.paidBy}</td>
          <td>${order.amount}</td>
          <td>{order.mrr}</td>
          <td>{invoice}</td>
        </tr>
      );
    });
    return (
      <>
        <Col>
          <h2>Order History</h2>
          <DenseTable>
            <Table striped bordered>
              <thead>
                <tr>
                  <th>Activation Date</th>
                  <th>Order ID</th>
                  <th>Pricing Version</th>
                  <th>Plan</th>
                  <th>Type</th>
                  <th>Term Length</th>
                  <th>Payment Mode</th>
                  <th>Paid by</th>
                  <th>Amount</th>
                  <th>MRR</th>
                  <th>Invoice Link</th>
                </tr>
              </thead>
              <tbody>{orderHistoryRendered}</tbody>
            </Table>
          </DenseTable>
        </Col>
      </>
    );
  }
}

OrderHistoryWidget.propTypes = {
  orderHistory: Proptype.array
};

export default OrderHistoryWidget;
