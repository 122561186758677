import React from 'react';
import { Tooltip } from 'reactstrap';

class SimpleTooltip extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false };

    this.toggle = () => {
      this.setState({ isOpen: !this.state.isOpen });
    };
    this.toggle = this.toggle.bind(this);
  }

  render() {
    return (
      <Tooltip
        isOpen={this.state.isOpen}
        toggle={this.toggle}
        autohide={false}
        {...this.props}
      />
    );
  }
}

/** Get a simple Tooltip.
 *
 * @param {string} id unique DOM id
 * @param {object} text Rendered Text
 * @param {object} toolTipText Rendered Tooltip Text
 * @param {string} direction of the tooltip.
 * @returns {object} a ReactStrap Tooltip and text.
 */
function getTooltip(id, text, toolTipText, direction = 'right') {
  return (
    <span>
      <span id={id}>{text}</span>
      <SimpleTooltip placement={direction} target={id} autohide={false}>
        {toolTipText}
      </SimpleTooltip>
    </span>
  );
}

export { SimpleTooltip, getTooltip };
