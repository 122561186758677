import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';

/**
 * Set the clipboard to given value
 *
 * @param {string} value The Value to put into clipboard
 */
function setClipboard(value) {
  const tempInput = document.createElement('input');
  tempInput.style = 'position: absolute;  left: -1000px; top: -1000px';
  tempInput.value = value;
  document.body.appendChild(tempInput);
  tempInput.select();
  document.execCommand('copy');
  document.body.removeChild(tempInput);
}

/**
 * A comp. that copies given text to clipboard on click
 * Note: Need to set the context by
 * SetClipBoardOnClick.contextType = Context;
 * The context should be a function
 * fn( alertType, alertText, alertVisibleTimeMs)
 */
class SetClipBoardOnClick extends React.Component {
  render() {
    const buttonStyle = { color: 'green' };

    const onClick = () => {
      const val = this.props.value;
      setClipboard(val);
      this.context('ALERT', `Copied '${val}' to Clipboard`, 2000);
    };
    const button = (
      <FontAwesomeIcon style={buttonStyle} onClick={onClick} icon={faCopy} />
    );

    return this.props.hideValue === true ? (
      button
    ) : (
      <>
        {this.props.value} {button}
      </>
    );
  }
}

SetClipBoardOnClick.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hideValue: PropTypes.bool
};

export { SetClipBoardOnClick };
