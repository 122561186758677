import React from 'react';
import PropType from 'prop-types';
import styled from 'styled-components';
import { FrozenHeadTable } from '../utils/tables/FrozenHead';
import {
  getMRR,
  getRenewalDate,
  giveAccount,
  processSubscriptionStatus,
  openOpportunities,
  getLookerLinks,
  getFreshdeskTickets
} from './helpers';

import { showSpinner } from '../utils/loadingSoon';

function isRowLoaded(row, fn) {
  if (row.isLoaded) return fn(row);
  return showSpinner();
}

function isCellReady(tableProps, fn) {
  if (tableProps.cell.row.original.isLoaded) return fn(tableProps);
  return showSpinner();
}

const WatchlistStyles = styled.div`
  span.expired.subStatusEntry {
    border-radius: 3px;
    background: #f0ddd5;
    display: inline-block;
    padding: 0px 3px 0px 3px;
    margin-top: 2px;
  }
`;

const FreeStyled = styled.span`
  color: crimson;
  text-decoration: line-through;
`;

const FreeStyledGreen = styled.span`
  color: green;
`;

class WatchlistTable extends React.Component {
  constructor(props) {
    super(props);

    const columns = [
      {
        Header: 'Sr. No',
        accessor: () => 'PENDING',
        Filter: ''
      },
      {
        Header: 'Account',
        Cell: giveAccount,
        accessor: row =>
          `${row.sfdcAccountName}\n${row.domainName}\n${row.groupId}\n${row.adminLink}`
      },
      {
        Header: 'Plans',
        Filter: '',
        Cell: tableProps => processSubscriptionStatus(tableProps),
        minWidth: '20rem'
      },
      {
        Header: 'MRR ($)',
        Filter: '',
        accessor: row => {
          const mrr = getMRR(row);
          if (isNaN(mrr)) {
            return '-';
          } else {
            return `$${Math.round(getMRR(row) * 100) / 100}`;
          }
        }
      },
      {
        Header: 'CSS',
        accessor: row => row.css
      },
      {
        Header: 'AE',
        accessor: row => row.ae
      },
      {
        Header: 'Open Opps',
        Cell: tableProps => openOpportunities(tableProps),
        accessor: row => row.openOpportunities.count,
        Filter: ''
      },
      {
        Header: 'Open Tickets',
        Cell: tableProps => getFreshdeskTickets(tableProps),
        accessor: row => row.freshdeskTickets,
        Filter: ''
      },
      {
        Header: 'Renewal Date',
        Cell: tableProps => {
          const originalRow = tableProps.cell.row.original || {};
          return getRenewalDate(originalRow);
        },
        accessor: row => Date.parse(getRenewalDate(row)),
        Filter: ''
      },
      {
        Header: 'Aut MAL(Last 30 days)',
        accessor: row =>
          `${
            row.automate_mal === null ? -1 : row.automate_mal.toFixed(4) * 100
          }%`,
        Filter: ''
      },
      {
        Header: 'App Aut MAL(Last 30 days)',
        accessor: row =>
          `${
            row.app_automate_mal === null
              ? -1
              : row.app_automate_mal.toFixed(4) * 100
          }%`,
        Filter: ''
      },
      {
        Header: 'Insights',
        Cell: tableProps => getLookerLinks(tableProps),
        Filter: ''
      },
      {
        Header: 'Remove',
        disableFilter: true
      }
    ];

    const newColumns = columns.map(col => {
      const newCol = { ...col };
      if (col.accessor) {
        newCol.accessor = row => isRowLoaded(row, col.accessor);
      }
      if (col.Cell) {
        newCol.Cell = tableProp => isCellReady(tableProp, col.Cell);
      }
      return newCol;
    });

    this.state = {
      columns: newColumns,
      isLoaded: false,
      error: null
    };

    this.addRow = this.addRow.bind(this);
  }

  addRow(newRow) {
    this.setState({
      rows: [...this.state.rows, newRow]
    });
  }

  render() {
    const maxHeight = '84vh';
    return (
      <WatchlistStyles>
        <FrozenHeadTable
          columns={this.state.columns}
          data={this.props.rows}
          removeRow={this.props.removeRow}
          maxHeight={maxHeight}
        />
        <div>
          Legend: <span className="expired subStatusEntry">Expired Plans</span>,{' '}
          <FreeStyled>$</FreeStyled>: Free Plan,{' '}
          <FreeStyledGreen>$</FreeStyledGreen>: Paid Plan
        </div>
      </WatchlistStyles>
    );
  }
}

WatchlistTable.propTypes = {
  rows: PropType.array,
  removeRow: PropType.func
};

export { WatchlistTable, getMRR, getRenewalDate };
