import React, { useState } from "react";
import { Button } from "reactstrap";
import PropTypes from 'prop-types'
import Popup from "reactjs-popup";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faCheckCircle,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";

import { SimpleTooltip } from "../../../utils/simpleToolTip";
import { ModalContext } from "../../../utils/alertModal";
import { SetClipBoardOnClick } from "../../../utils/clipboard";
import config from "../../../../config";

SetClipBoardOnClick.contextType = ModalContext;

const spanForNo = <span className="boolCell no">No</span>;
const spanForYes = <span className="boolCell yes">Yes</span>;
const formatYesNo = (x) => (x === "Yes" ? spanForYes : spanForNo);
const isYesNo = (val) => (val === null ? "No" : val ? "Yes" : "No");

/**
 * A functino that returns pop up with previous opp details
 *
 * @param {object} row The Row
 * @returns {object} The rendered popup
 */
function getOppDetails(row) {
  const OppStyle = styled.div`
    table th,
    table tr {
      padding: 0.25rem;
      // border: thing solid grey;
    }
    td {
      padding: 0.25rem;
    }
    .highlightTd {
      color: blue;
    }
    
    .tableHeader {
      font-weight: bold;
    }
  `;

  const result = row.sfdc_opps.map((r) => {
    let className = "";
    if (r.opp_id === r.relevant_opp) className = "highlightTd";

    return (
      <tr className={className} key={r.opp_id}>
        <td>{r.opp_id}</td>
        <td>
          <a href={`${config.sfdcBaseUrl}${r.opp_id}`}>{r.opp_name}</a>
        </td>
        <td>{r.close_date.value}</td>
        <td>{isYesNo(r.is_recon)}</td>
        <td>
          <a href={`${config.sfdcBaseUrl}${r.quote_id}`}>{r.quote_name}</a>
        </td>
        <td>{r.baseline_arr}</td>
        <td>{r.attainment_i_acv_c}</td>
      </tr>
    );
  });

  return (
    <OppStyle>
      <table>
        <tbody>
          <tr className="tableHeader" key="header">
            <td>Opp Id</td>
            <td>Name</td>
            <td>Close Date</td>
            <td>Recon?</td>
            <td>Quote</td>
            <td>Baseline(ARR)</td>
            <td>Incr(ARR)</td>
          </tr>
          {result}
        </tbody>
      </table>
    </OppStyle>
  );
}

/**
 * returns [OppDetails Rendered, oppDetails-text only]
 *
 * @param {*} row object with row data
 * @returns {[object, string]} rendered and string oppDetails
 */
const oppDetails = (row) => {
  const StyledPopup = styled.div`
    .popup-content {
      width: 50rem !important;
    }
  `;

  const trigger = (
    <span>
      <span>{row.opportunity_id} </span>
      <SetClipBoardOnClick value={row.opportunity_id} hideValue={true} />
    </span>
  );
  const opp = (
    <>
      <StyledPopup>
        <Popup trigger={trigger} position="bottom left" on="hover">
          {getOppDetails(row)}
        </Popup>
      </StyledPopup>
    </>
  );

  if (isYesNo(row.opp_details.is_recon) === "Yes") {
    return [
      <>
        {opp}
        Order Id:
        <SetClipBoardOnClick value={row.opp_details.order_id} />
      </>,
      `${row.opportunity_id} - ${row.opp_details.order_id}`,
    ];
  }
  return [
    <>
      {opp}
      Req Id:
      <br />
      <SetClipBoardOnClick value={row.opp_details.request_id} />
    </>,
    `${row.opportunity_id} - ${row.opp_details.request_id}`,
  ];
};

const renderSubState = (state, order, subState) => {
  return state[order][subState].map((x) => (
    <div key={subState}>
      <div style={{ marginLeft: "0.5rem" }}>
        MRR: {x.mrr}
        <br />
        Term: {x.term}
        <br />
        Plan:{x.name}({x.family})<br />
      </div>
    </div>
  ));
};

const getOrders = (row) => {
  const orders = Array.from(new Set(row.all_orders_req_details))
    .sort()
    .reverse();
  const { states } = row;

  const current = row.opp_details.current_order_number;
  const previous = row.opp_details.previous_order_number;

  if (!states)
    return orders.map((order) => {
      let className;
      if (order === current) className = "current_order";
      else if (order === previous) className = "previous_order";

      return (
        <span key={order} className={className}>
          {order}
        </span>
      );
    });

  const renderedOrders = orders.map((order) => {
    const id = `order-${row.opportunity_id}-${order}`;
    let tooltip = null;

    const subs = row.subscription_info.filter((x) =>
      x ? x.order_number === order : false
    );

    let orderDate;
    let ced;
    if (subs.length > 0) {
      let d = new Date(0);
      d.setUTCSeconds(parseFloat(subs[0].order_date));
      orderDate = d.toLocaleDateString("en-IN");
      d = new Date(0);
      d.setUTCSeconds(parseFloat(subs[0].contract_effective_date));
      ced = d.toLocaleDateString("en-IN");
    }

    if (order in states && states[order]) {
      tooltip = (
        <SimpleTooltip key={`tooltip-${order}`} placement="right" target={id}>
          <div style={{ textAlign: "left" }}>
            {subs.length > 0 ? (
              <>
                Order Created: {orderDate}
                <br />
                CED: {ced}
                <br />
              </>
            ) : null}
            Removed: {renderSubState(states, order, "removed")}
            <br />
            Persisted: {renderSubState(states, order, "persisted")}
            <br />
            Added: {renderSubState(states, order, "added")}
            <br />
            Cancelled: {renderSubState(states, order, "cancelled")}
            <br />
          </div>
        </SimpleTooltip>
      );
    }

    let className;
    if (order === current) className = "current_order";
    else if (order === previous) className = "previous_order";

    return (
      <span key={order}>
        <span key={id} id={id} className={className}>
          {order}
        </span>
        {", "}
        {tooltip}
      </span>
    );
  });

  return renderedOrders;
};

/**
 * A simple button that gets disabled while the async handler executes
 *
 * @param {object} param0 the main param
 * @param {Function} param0.job Fn to run on click(must be async)
 * @param {Function} param0.color Check reactstrap button colors
 * @param {object} param0.others all the other props on the component
 * @param {object} param0.completionTarget the element to show on completion
 * @returns {object} the rendered button
 */
function ButtonWithAsyncJob({ color, job, children, completionTarget = null, ...others }) {
  const [started, setStarted] = useState(false);
  const [isWorking, setIsWorking] = useState(false);
  const [icon, setIcon] = useState(null);
  const onClick = () => {
    setStarted(true);
    setIsWorking(true);
    setIcon(<FontAwesomeIcon icon={faSpinner} />);
    job()
      .then(() => {
        setIsWorking(false);
        if (completionTarget) setIcon(completionTarget);
        else setIcon(<FontAwesomeIcon icon={faCheckCircle} />);
      })
      .catch((err) => {
        console.error(err)
        console.error(err.stack)
        setIsWorking(false);

        const ran = Math.random() * 100000;

        setIcon(
        <FontAwesomeIcon id={ran} icon={faExclamationTriangle} />);
        <SimpleTooltip key={ran} placement="right" target={ran}>
          Error: {err}
          <hr/>
          Stack: {err.stack}
        </SimpleTooltip>
      });
  };

  const props = {};
  if (isWorking) props.disabled = true;

  let badge;
  if (started) {
    if (completionTarget) {
      badge = (<>
        {isWorking && icon}
        <span style={{display: isWorking ? 'none' : 'inherit' }}> {completionTarget} </span>
      </>);
    } else badge = icon;
  }

  return (
    <Button
      color={color}
      size="sm"
      className="mr-2"
      onClick={onClick}
      {...props}
      {...others}
    >
      {children} {' '} {badge}
    </Button>
  );
}

ButtonWithAsyncJob.propTypes = {
  color: PropTypes.string,
  job: PropTypes.func,
  children: PropTypes.node,
  completionTarget: PropTypes.node
}

export { getOrders, oppDetails, formatYesNo, isYesNo, ButtonWithAsyncJob };
