const { merge } = require('lodash');

const connection = {
  hostname: 'eds.browserstack.com',
  port: 443,
  api_key: '3T5kkUTZ2cGiy0zhLwyxBdDbx0GeJuZQd'
};
const events = {
  default: 'hawkeyePerf-test-v2',
  performance: 'hawkeye-performance-v1'
};

if (process.env.NODE_ENV === 'development') {
  events.performance = 'hawkeye-performance-beta-4';
}

/** Send an EDS event with `event_type` = web_events
 *
 * @param {string} eventName The key to the event type in the config
 * @param {object} extraData The data to be attached to the event
 */
function sendEvent(eventName, extraData) {
  if (!(eventName in events)) {
    return;
  }
  const eventData = {
    event_type: 'web_events',
    data: merge(
      {},
      {
        event_name: events[eventName],
        eds_timestamp: parseInt(new Date().getTime() / 1000, 10)
      },
      extraData
    ),
    api_key: connection.api_key
  };

  const edsURL = `https://${connection.hostname}:${connection.port}/send_event`;

  fetch(edsURL, {
    method: 'POST',
    body: JSON.stringify(eventData),
    headers: { 'Content-Type': 'application/json' }
    // mode: 'cors',
    // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    // credentials: 'same-origin', // include, *same-origin, omit
    // redirect: 'follow', // manual, *follow, error
    // referrer: 'no-referrer', // no-referrer, *client
  });
}

/** Send an EDS event with `event_type` = web_events
 * Also add in user_id
 *
 * @param {string} eventName The key to the event type in the config
 * @param {object} extraData The data to be attached to the event
 * @param {any} userId The user id for hawkeye
 */
function sendEventWithUser(eventName, extraData = {}, userId = undefined) {
  // eslint-disable-next-line  no-param-reassign
  extraData.user_id = userId;
  sendEvent(eventName, extraData);
}

module.exports = {
  sendEvent,
  sendEventWithUser
};
