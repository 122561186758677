import React, { useContext, useState, useEffect } from 'react';
import { useAsync } from 'react-async';
import styled from 'styled-components';
import { Container, Row, Col } from 'reactstrap';

import ErrorBoundary from '../utils/errorBoundary';
import AuthContext from '../context';
import config from '../../config';
import {
  values,
  difference,
  intersection,
  pickBy,
  reduce,
  groupBy
} from 'lodash-es';

import { WatchlistTable } from '../watchListComponents/watchListTable';
import {
  AddGroupToWatchlistWidget,
  SimpleStatsRow
} from '../watchListComponents/WatchlistWidgets';
import { useRefState } from '../utils/hooks';
import {
  fetchRetry,
  fetchWithRollback,
  recordTime
} from '../utils/customFetch';
import { sendEvent } from '../utils/eds';
import { ModalContext } from '../utils/alertModal';

const [recordTimeCallback, getTimeData] = recordTime();

const ColStyle = styled.div`
  div.col {
    height: 100%;
  }
`;

/** A function that returns an addGroups callback configured with the current
 * closure.
 *
 * @param {object} rowDataRef A react reference that gives a current value for rowData
 * @param {Function} setRowData function that can update the rowsData.
 * @param {Function} alertFunction An alerting function for passing messages to users.
 * @returns {Function} an addGroups callback configured with closures.
 */
function getAddGroupsCallback(rowDataRef, setRowData, alertFunction) {
  return async groupIds => {
    const availableRowData = values(rowDataRef.current).map(x => x.groupId);
    const newGroups = difference(groupIds, availableRowData);
    const alreadyPresent = intersection(groupIds, availableRowData);
    if (alreadyPresent.length !== 0) {
      alertFunction('ALERT', `[${alreadyPresent}] already exists.`, 2000);
    }

    newGroups.map(async groupId => {
      alertFunction('NOTE', `Adding Group:${groupId} to the watchlist.`, 2000);
      const rowPromise = loadGroupDetails(groupId);
      fetchWithRollback(
        'POST',
        `${config.urls.addToWatchlist}${groupId}`,
        () => alertFunction('ERROR', `Failed to Add group: ${groupId}`, 2000),
        async () => {
          setRowData({ ...rowDataRef.current, [groupId]: { isLoaded: false } });
          const row = await rowPromise;
          row.isLoaded = true;
          setRowData({ ...rowDataRef.current, [groupId]: row });
          alertFunction('SUCCESS', `Loaded group ${groupId}`, 2000);
        }
      );
    });
  };
}

/** A function that returns an removeGroup callback configured with the current
 * closure.
 *
 * @param {object} rowDataRef A react reference that gives a current value for rowData
 * @param {Function} setRowData function that can update the rowsData.
 * @param {Function} alertFunction An alerting function for passing messages to users.
 * @returns {Function} an removeGroup callback configured with closures.
 */
function getRemoveGroupCallback(rowDataRef, setRowData, alertFunction) {
  return async groupId => {
    const findRow = rowDataRef.current[groupId];
    if (!findRow) return { status: false, message: 'No such Group' };
    let row = findRow; // Needed to add back the deleted row if the DB deletion failed.

    setRowData(pickBy(rowDataRef.current, (_, key) => key != groupId));
    fetchWithRollback(
      'DELETE',
      `${config.urls.removeFromWatchlist}${groupId}`,
      () => {
        alertFunction(
          'ERROR',
          `Failed to Remove ${groupId}, please retry.`,
          5000
        );
        setRowData({ ...rowDataRef.current, [groupId]: row });
      },
      () => alertFunction('NOTE', `Removed Group:${groupId}`, 2000)
    );
  };
}

/**
 * return a fetch function for useAsync
 *
 * @param {string} name The name of the endpoint to use in perf statistics
 * @param {string} id An identifier if needed for stats
 * @param {string} path the url to hit and get the dat
 * @returns {Function} useAsync compatible fetch function
 */
function customFetchForAsync(name, id, path) {
  return async (props, { signal }) =>
    fetchRetry(name, id, recordTimeCallback, path, {
      signal,
      credentials: 'include',
      Accept: 'application/json'
    });
}

const loadWatchlistOnlyGroupIds = customFetchForAsync(
  'groupIds',
  '<>',
  config.urls.getWatchlistOnlyGroupId
);
const loadWatchlistComplete = customFetchForAsync(
  'groupIdWithDetails',
  '<>',
  config.urls.getAllWatchlistData
);

const loadGroupDetails = async groupId => {
  const options = { credentials: 'include', Accept: 'application/json' };
  const groupData = await Promise.all([
    fetchRetry(
      'groupDetails',
      groupId,
      recordTimeCallback,
      `${config.urls.getGroupDetails}${groupId}`,
      options
    )
  ]);
  return { ...groupData[0] };
};

/**
 * A improved Watchlist
 *
 * @returns {object} The Rendered watchlist
 */
export default function WatchlistTab() {
  const [rowData, rowDataRef, setRowData] = useRefState({});
  const addMessage = useContext(ModalContext);
  const authContextData = React.useContext(AuthContext);

  const groupIdsAsync = useAsync({ promiseFn: loadWatchlistOnlyGroupIds });
  const allDetailsAsync = useAsync({ promiseFn: loadWatchlistComplete });

  const error = groupIdsAsync.error;
  const [loadPerfData, setLoadPerfData] = useState(null);

  useEffect(() => {
    setLoadPerfData({ start: performance.now(), processed: false }); // For Benchmark
    if (error)
      alert(`Error: ${error.name}: ${error.message} Stack: ${error.stack}`);
    else {
      if (groupIdsAsync.isResolved) {
        const groupIds = groupIdsAsync.data.groupIds;
        setRowData(
          groupBy(
            groupIds.map(groupId => ({ groupId, isLoaded: false })),
            'groupId'
          )
        );
      }
      if (allDetailsAsync.isResolved) {
        let rows = allDetailsAsync.data || {};
        const groupIds = Object.keys(rows);
        groupIds.forEach(groupId => (rows[groupId].isLoaded = true));
        setRowData(rows);

        sendEvent('performance', {
          totalTimeTaken: performance.now() - loadPerfData.start,
          requestType: 'watchlistTab',
          subRequests: getTimeData(),
          user_id: authContextData.userID
        });
        setLoadPerfData({ processed: true });
      }
    }
  }, [error, groupIdsAsync.isResolved, allDetailsAsync.isResolved]);

  const rows = values(rowData);
  return (
    <ErrorBoundary>
      <Container fluid className="mt-2">
        <ColStyle>
          <Container fluid className="ml-2 mb-2">
            <Row>
              <Col style={{ flexGrow: 2 }}>
                <AddGroupToWatchlistWidget
                  addGroups={getAddGroupsCallback(
                    rowDataRef,
                    setRowData,
                    addMessage
                  )}
                />
              </Col>
              <Col style={{ flexGrow: 4 }}>
                <SimpleStatsRow groupsDetails={rows} />
              </Col>
            </Row>
          </Container>
        </ColStyle>

        <ErrorBoundary>
          <WatchlistTable
            rows={rows}
            removeRow={getRemoveGroupCallback(
              rowDataRef,
              setRowData,
              addMessage
            )}
          ></WatchlistTable>
        </ErrorBoundary>
      </Container>
    </ErrorBoundary>
  );
}
