import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import WatchlistTab from './tabs/watchlistTab';
import GroupDetailsTab from './tabs/groupDetailsTab';
import MqlIntelligence from './tabs/mqlIntelligence';
import ToolTab from './tabs/toolsTab';
import Settings from './tabs/settingsTab'

import Header from './header';

import useTabs from './utils/tabWithState';
import { AlertModal, ModalContext, useModalAlert } from './utils/alertModal';
import AccountSummaryTab from './tabs/accountSummaryTab';

const HiddenTabStyle = styled.div`
  .hiddenTab {
    display: none;
  }
`;

/** The Tabbed home Component.
 *
 * @param {object} props React Props
 * @returns {React.Component} Home Component
 */
function TabbedHome(props) {
  const {
    addTab,
    removeTab,
    getActiveTabTitle,
    setActiveTab,
    getAllTabTitles,
    getTabs
  } = useTabs({ initialTabs: {}, initialActiveTab: '' });

  const openWatchList = () => {
    addTab('My Watchlist', <WatchlistTab created={new Date()} />);
    setActiveTab('My Watchlist');
  };

  useEffect(() => {
    const {
      params: { groupId, domainName, inputValue },
      path
    } = props.match;
    if (groupId && !(`Group:${groupId}` in getAllTabTitles())) {
      addTab(`Group:${groupId}`, <GroupDetailsTab groupId={groupId} />);
      setActiveTab(`Group:${groupId}`);

    } else if (path === "/tools") {
      addTab("Tools Panel", <ToolTab />);
      setActiveTab("Tools Panel");
    } else if (path === "/mql_intelligence/:inputValue?") {
      addTab("MQL Intelligence", <MqlIntelligence inputValue={inputValue} />);
      setActiveTab("MQL Intelligence");
    } else if (domainName && !(`Account:${domainName}` in getAllTabTitles())) {
      addTab(
        `Account:${domainName}`,
        <AccountSummaryTab domainName={domainName} />
      );
      setActiveTab(`Account:${domainName}`);
    } else if (path === '/tools') {
      addTab('Tools Panel', <ToolTab />);
      setActiveTab('Tools Panel');
    } else if (path === '/settings') {
      addTab('Settings', <Settings />);
      setActiveTab('Settings');
    } else {
      openWatchList();
      setActiveTab("My Watchlist");
    }
  }, [props.match]);

  const tabs = getTabs();
  const renderedTabs = Object.keys(tabs).map(tabName => (
    <HiddenTabStyle key={tabName}>
      <div className={tabName !== getActiveTabTitle() ? 'hiddenTab' : ''}>
        {tabs[tabName]}
      </div>
    </HiddenTabStyle>
  ));

  const [messageArray, addMessage, clearMessages] = useModalAlert();

  return (
    <div>
      <Header
        tabTitles={getAllTabTitles()}
        activeTab={getActiveTabTitle()}
        closeTab={removeTab}
        openWatchList={openWatchList}
      />
      <ModalContext.Provider value={addMessage}>
        {/* Pass the addMessage function down. */}
        {renderedTabs}
      </ModalContext.Provider>
      <AlertModal
        messages={messageArray}
        clearMessages={clearMessages}
      ></AlertModal>
    </div>
  );
}

TabbedHome.propTypes = {
  match: PropTypes.object
};

export default TabbedHome;
