import React from 'react';
import _baseTab from '../utils/_baseTab';
import UsersWidget from '../groupsWidgets/usersWidget';
import { loadingSoon } from '../utils/loadingSoon';

export class UsersSubTab extends _baseTab {
  render() {
    const { isLoaded, loadedData, reload } = this.props;
    return (
      <>
        {loadingSoon(
          isLoaded.powerUsers,
          <UsersWidget
            subgroupInfo={loadedData?.subgroupInfo}
            powerUsers={loadedData?.powerUsers}
            reload={reload}
          />
        )}
      </>
    );
  }
}
