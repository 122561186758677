import _baseTab from '../../../utils/_baseTab';
import React from 'react';
import Proptype from 'prop-types';
import { CSVReader } from 'react-papaparse';
import { Button } from 'reactstrap';
import CsvTable from './csvTable';
import config from '../../../../config';
import ErrorBoundary from '../../../utils/errorBoundary';
import { postJson } from '../../../utils/customFetch';

class TablePreview extends _baseTab {
  constructor(props) {
    super(props);
  }

  render() {
    const rows = this.props.rows;
    if (rows && rows.length > 0) {
      return (
        <div>
          <h3>File Preview</h3>
          <div className="ar-table-content">
            <CsvTable data={this.props.rows} />
          </div>
          <div>
            <Button
              onClick={() => {
                this.props.submit(this.props.rows);
              }}
            >
              Submit for AR update
            </Button>
          </div>
        </div>
      );
    }
    return '';
  }
}

class ErrorPreview extends _baseTab {
  constructor(props) {
    super(props);
  }
  render() {
    const response = this.props.response;
    if (response && response.length > 0) {
      const renderedResponse = response.map(resp => {
        return (
          <div key={resp.invoice}>
            {`Invoice: ${resp.invoice} => Status: ${resp.status}`}
          </div>
        );
      });
      return (
        <div>
          <h3>Response</h3>
          <div>{renderedResponse}</div>
        </div>
      );
    }
    return '';
  }
}

class ARUpdateTab extends _baseTab {
  constructor(props) {
    super(props);
    this.state = {
      listData: [],
      error: '',
      responseData: []
    };

    this.handleSubmit = rows => {
      this.makePostRequest(config.urls.aRUpdate, { data: rows }).then(
        response => {
          if (response.error) {
            alert(response.error);
          } else {
            this.setState({
              responseData: response.data,
              listData: []
            });
          }
        }
      );
    };
  }

  async makePostRequest(url, body) {
    const requestObject = await fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body)
    });
    return requestObject.json();
  }

  handleOnDrop = data => {
    const rows = data.map(function(row) {
      return row.data;
    });
    this.setState({ listData: rows });
  };

  handleOnError = (err, file, inputElem, reason) => {
    console.log(err);
  };

  handleOnRemoveFile = data => {
    this.setState({ listData: [] });
    this.setState({ responseData: [] });
  };

  render() {
    return (
      <ErrorBoundary>
        <div>
          <div className="ar-update-content">
            <div className="ar-fileupload">
              <h5>Upload CSV File</h5>
              <CSVReader
                onDrop={this.handleOnDrop}
                onError={this.handleOnError}
                addRemoveButton
                onRemoveFile={this.handleOnRemoveFile}
              >
                <span>click here to upload file.</span>
              </CSVReader>
            </div>
            <TablePreview
              rows={this.state.listData}
              submit={this.handleSubmit}
            />
            <ErrorPreview response={this.state.responseData} />
          </div>
        </div>
      </ErrorBoundary>
    );
  }
}

TablePreview.propTypes = {
  rows: Proptype.array,
  submit: Proptype.func
};

ErrorPreview.propTypes = {
  response: Proptype.array
};

export default ARUpdateTab;
