import React from 'react';
import Proptype from 'prop-types';
import { mapValues, values } from 'lodash-es';
import { Table, Col, Row } from 'reactstrap';
import { DenseTable } from '../utils/_styles';
import constants from '../../constants';

class HealthScoreMetricsWidget extends React.Component {
  render() {
    const { healthScoreInfo } = this.props;
    if (!healthScoreInfo) return <div style={{fontSize: "14px", marginLeft: "12px", fontWeight: "bold"}}>Loading Health Score Failed.</div>;
    if (healthScoreInfo.length == 0) return <div style={{fontSize: "14px", marginLeft: "12px", fontWeight: "bold"}}>Health Score data not available.</div>;
  
    const healthScoreData = [];
    const healthScoreObject = healthScoreInfo[0];
    for(const product in healthScoreObject.products){
      if(Object.keys(healthScoreObject.products[product]).length != 0){
        const fragment = <React.Fragment key={product}>
          <tr>
            <td key="productName">{constants.product_keys[product]}</td>
            <td key="productHealthScore">
              {healthScoreObject.products[product].health_score}
            </td>
            <td key="onboarded">
              {healthScoreObject.products[product].onboarding.status == 1 ? 'TRUE' : 'FALSE'}
            </td>
            <td key="adoption">{healthScoreObject.products[product].adoption}</td>
            <td key="productMRR">{healthScoreObject.products[product].mrr}</td>
          </tr>
        </React.Fragment>
        healthScoreData.push(fragment)
      }
    }
    return (
      <>
        <Col>
          <h2>{'\u00A0'}</h2>
          {/* For aligning to the subscription status */}
          <Row>
            <div style={{ fontSize: "20px", fontWeight: "bold", marginLeft: "15px" }}>Total Health Score: </div> 
            <div style={{ fontSize: "16px", marginLeft: "15px", marginTop: "4px", fontWeight: "bold" }}>{healthScoreObject.total_health_score}</div> 
          </Row>
          <div style={{ marginBottom: "10px" }}></div> 
          <DenseTable>
            <Table striped bordered>
              <thead>
                <tr>
                  <th>Product Name</th>
                  <th>Product Health Score</th>
                  <th>Onboarded</th>
                  <th>Adoption (%)</th>
                  <th>Product MRR ($)</th>
                </tr>
              </thead>
              <tbody>{healthScoreData}</tbody>
            </Table>
          </DenseTable>
        </Col>
      </>
    );
  }
}

HealthScoreMetricsWidget.propTypes = {
  healthScore: Proptype.object
};

export default HealthScoreMetricsWidget;
