import React from 'react';
import { Jumbotron, Container } from 'reactstrap';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';

import AuthCheckAndSignIn from './Components/auth';
import TabbedHome from './Components/home';
import ErrorBoundary from './Components/utils/errorBoundary';
import { GlobalStyles } from './Components/utils/_styles';

/** Functional Component that is rendered if the route does not match any
 * frontend component.
 *
 * @returns {React.Component} The Page Not Found Component.
 */
function PageNotFound() {
  return (
    <Jumbotron fluid>
      <Container fluid>
        <h1>Hey, it seems that you have found an unknown page!</h1>
        <p>
          Please send any suggestions you have for this page to Systems Team.
          :-)
          <br />
          Click <Link to="/"> here </Link>
          to go back to home page.
        </p>
      </Container>
    </Jumbotron>
  );
}

/** The Core mounting point of the application that links together all Components.
 *
 * @returns {React.Component} Main app
 */
function App() {
  return (
    <ErrorBoundary>
      <GlobalStyles>
        <Router>
          <AuthCheckAndSignIn>
            <div>
              <Switch>
                <Route exact path="/" component={TabbedHome} />
                <Route path="/group/:groupId([0-9]+)" component={TabbedHome} />
                <Route path="/account/:domainName" component={TabbedHome} />
                <Route path="/tools" component={TabbedHome} />
                <Route path="/mql_intelligence/:inputValue?" component={TabbedHome} />
                <Route path="/settings" component={TabbedHome} />
                <Route path="*" component={PageNotFound} />
              </Switch>
            </div>
          </AuthCheckAndSignIn>
        </Router>
      </GlobalStyles>
    </ErrorBoundary>
  );
}

export default App;
