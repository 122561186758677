import Proptype from 'prop-types';
import React, { Suspense } from 'react';
import ErrorBoundary from '../utils/errorBoundary';
import { Container } from 'reactstrap';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
dayjs.extend(customParseFormat);
dayjs.extend(utc);

import * as Sentry from '@sentry/browser';

import { get, merge } from 'lodash-es';

import MainTab from '../utils/mainTabs';
import config from '../../config';
import constants from '../../constants';
import _baseTab from '../utils/_baseTab';
import { showSpinner } from '../utils/loadingSoon';

const FreeTrialsSubTab = React.lazy(() => import('./freeTrialsSubTab'));
const CustomersSubTab = React.lazy(() => import('./customersSubTab'));
const SummarySubTab = React.lazy(() => import('./summarySubTab'));
const LeadInfoSubTab = React.lazy(() => import('./leadInfoSubTab'));

import { loadingSoon } from '../utils/loadingSoon';
import { timedFetch, recordTime } from '../utils/customFetch';
import AuthContext from '../context';

// Used for getting a stateful time recording callback
const [recordTimeCallback] = recordTime();

/** Get the data at while using the Cookies
 *
 * @param {string} name Type of object
 * @param {string} baseUrl the baseUrl for the object
 * @param {string} id the object id
 * @returns {Promise} the resolves to the fetched data or rejects with an error
 */
async function fetchData(name, baseUrl, id) {
  const url = `${baseUrl}${id}`;
  try {
    const requestObject = await timedFetch(name, id, recordTimeCallback, url, {
      credentials: 'include'
    });
    return await requestObject.json();
  } catch (error) {
    Sentry.withScope(scope => {
      scope.setExtras({ url, type: 'fetchData' });
      Sentry.captureException(error);
    });
    return null;
  }
}

class AccountSummaryTab extends _baseTab {
  // static contextType = AuthContext;
  constructor(props) {
    super(props);

    this.state = {
      domainName: props.domainName,
      isLoaded: {
        freeTrials: false,
        accountDetails: false,
        leadInfo: false
      },
      loadedData: {
        freeTrials: null,
        accountDetails: null,
        leadInfo: {
          leadInfo: null,
          mqlEvents: null,
          templateHash: null,
          segmentHash: null,
          productInterest: null
        }
      },
      initialActiveTab: 'Summary'
    };
  }

  // TODO Add Failure cleanup
  fetchAndSet(baseUrl, id, objectName, path = null) {
    fetchData(objectName, baseUrl, id).then(object => {
      this.setState(
        merge(this.state, {
          isLoaded: {
            [objectName]: true
          },
          loadedData: {
            [objectName]: path ? get(object, path) : object
          }
        })
      );
    });
  }

  async componentDidMount() {
    this.fetchAndSet(
      config.urls.getAccountDetails,
      this.props.domainName,
      'accountDetails'
    );
  }

  handleTabChange = tab => {
    if (
      tab == constants.tabs.account_summary.free_trials &&
      !this.state?.isLoaded?.freeTrials
    ) {
      this.fetchAndSet(
        config.urls.getFreeTrials,
        this.props.domainName,
        'freeTrials'
      );
    } else if (
      tab == constants.tabs.account_summary.mql_intelligence &&
      !this.state?.isLoaded?.leadInfo
    ) {
      this.fetchAndSet(
        config.urls.getLeadInfo,
        this.props.domainName,
        'leadInfo'
      );
    }
  };

  render() {
    const tabs = {
      [constants.tabs.account_summary.summary]: (
        <Suspense fallback={showSpinner()}>
          <SummarySubTab
            isLoaded={this.state.isLoaded}
            loadedData={this.state.loadedData?.accountDetails}
          />
        </Suspense>
      ),
      [constants.tabs.account_summary.customers]: (
        <Suspense fallback={showSpinner()}>
          <CustomersSubTab
            isLoaded={this.state.isLoaded}
            loadedData={this.state.loadedData?.accountDetails}
          />
        </Suspense>
      ),
      [constants.tabs.account_summary.free_trials]: (
        <Suspense fallback={showSpinner()}>
          <FreeTrialsSubTab
            isLoaded={this.state.isLoaded}
            loadedData={this.state.loadedData?.freeTrials}
          />
        </Suspense>
      ),
      [constants.tabs.account_summary.mql_intelligence]: (
        <Suspense fallback={showSpinner()}>
          <LeadInfoSubTab
            isLoaded={this.state.isLoaded}
            loadedData={this.state.loadedData?.leadInfo}
          />
        </Suspense>
      )
    };

    return (
      <ErrorBoundary>
        <Container fluid className="mt-2">
          {loadingSoon(
            this.state?.isLoaded?.accountDetails,
            <Container fluid>
              <h1>Parent Account: {this.props.domainName}</h1>
            </Container>,
            3,
            'title'
          )}
          <MainTab
            tabs={tabs}
            initialActiveTab={this.state.initialActiveTab}
            domainName={this.props.domainName}
            onTabChange={this.handleTabChange}
          />
        </Container>
      </ErrorBoundary>
    );
  }
}

AccountSummaryTab.contextType = AuthContext;
AccountSummaryTab.propTypes = {
  domainName: Proptype.string
};

export default AccountSummaryTab;
