const constants = {
  tabs: {
    account_summary: {
      summary: 'Summary',
      customers: 'Customers',
      free_trials: 'Free Trials',
      mql_intelligence: 'MQL Intelligence'
    },
    group_summary: {
      group_details: 'Group Details',
      users_info: 'Users Info',
      build_metrics: 'Build Metrics'
    }
  },
  product_keys: {
    live: 'LIVE',
    app_live: 'APP LIVE',
    automate: 'AUTOMATE',
    app_automate: 'APP AUTOMATE',
    enterprise: 'ENTERPRISE',
    percy: 'PERCY'
  }
};
module.exports = constants;
