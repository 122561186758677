import React from 'react';
import styled from 'styled-components';

import ErrorBoundary from '../../../utils/errorBoundary';
import NetAcvTable from './tableDef';

const NetAcvStyle = styled.div`
  .mainContent {
    overflow-y: scroll;
  }
`;

/**
 * Contains the NetAcv Tool  that
 *
 * @returns {object} The rendered object for Net ACV Tool
 */
function NetAcvTool() {
  return (
    <ErrorBoundary>
      <NetAcvStyle>
        <div className="mainContent">
          <NetAcvTable></NetAcvTable>
        </div>
      </NetAcvStyle>
    </ErrorBoundary>
  );
}

export default NetAcvTool;
