import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTable, useBlockLayout, useFilters } from 'react-table';
import { VariableSizeList } from 'react-window';
import { intersection } from 'lodash';

import { useWindowSize } from '../../../utils/hooks';
import { getColumnFilter } from '../../../utils/_filters';

// eslint-disable-next-line jsdoc/require-jsdoc
function NetAcvTable({
  columns,
  data,
  itemSize,
  filteredOppIds,
  filteredGroupIds
}) {
  const defaultColumn = React.useMemo(
    () => ({
      width: 200,
      minWidth: 50,
      maxWidth: 400,
      Filter: getColumnFilter('8rem')
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    totalColumnsWidth,
    prepareRow
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      autoResetFilters: false
    },
    useBlockLayout,
    useFilters
  );
  rows.sort((a,b) => {
    if(a.original.created_at > b.original.created_at){
      return 1;
    }
    if(a.original.created_at < b.original.created_at){
      return -1;
    }
    return 0;
  })
  const filteredOpps = filteredOppIds.current;
  const newOpps = rows.map(x => x.original.opportunity_id);
  const newGroups = rows.map(x => x.original.group_id);
  const combo = intersection(newOpps, filteredOpps);

  useEffect(() => {
    // eslint-disable-next-line no-param-reassign
    filteredOppIds.current = newOpps;
    filteredGroupIds.current = newGroups;
  }, [combo.length, newOpps.length]);

  const RenderRow = React.useCallback(
    ({ index, style }) => {
      const row = rows[index];
      prepareRow(row);
      return (
        <div
          {...row.getRowProps({
            style
          })}
          className="tr"
        >
          {row.cells.map(cell => {
            if (cell.column.Header === 'Sr. No.') {
              // For Row number
              return (
                <div className="td" {...cell.getCellProps()}>
                  {index + 1}
                </div>
              );
            }
            return (
              // eslint-disable-next-line react/jsx-key
              <div className="td" {...cell.getCellProps()}>
                {cell.render('Cell')}
              </div>
            );
          })}
        </div>
      );
    },
    [prepareRow, rows]
  );

  const size = useWindowSize();

  // Render the UI for your table
  return (
    <div
      {...getTableProps()}
      className="table"
      style={{ width: `${totalColumnsWidth}px` }}
    >
      <div>
        {headerGroups.map((headerGroup, index) => (
          <div
            key={index}
            {...headerGroup.getHeaderGroupProps()}
            className="tr"
          >
            {headerGroup.headers.map((column, i) => (
              <div key={i} {...column.getHeaderProps()} className="th">
                {column.render('Header')}
                <br />
                {column.canFilter ? (
                  column.render('Filter')
                ) : (
                  <Fragment></Fragment>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>

      <div {...getTableBodyProps()}>
        <VariableSizeList
          height={size.height - 200}
          itemCount={rows.length}
          itemSize={itemSize}
          width={totalColumnsWidth}
        >
          {RenderRow}
        </VariableSizeList>
      </div>
    </div>
  );
}

NetAcvTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  itemSize: PropTypes.func,
  filteredOppIds: PropTypes.object,
  filteredGroupIds: PropTypes.object
};

export default NetAcvTable;
