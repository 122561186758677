/** A wrapper around fetch that does performance measure
 *
 * @param  {string} name Name under which the metric is saved.
 * @param  {number} id The object id, or '<>'
 * @param  {Function} callback callback to call when fetch is done
 * @param  {...any} args All arguments to fetch() API
 * @returns {Promise} resolves similarly to fetch
 */
function timedFetch(name, id, callback, ...args) {
  return new Promise((resolve, reject) => {
    const startTime = performance.now();
    fetch(...args)
      .then(data => {
        callback(name, id, true, performance.now() - startTime);
        resolve(data);
      })
      .catch(err => {
        callback(name, id, false, performance.now() - startTime);
        reject(err);
      });
  });
}

/** A function that returns
 * 1) a callback that records the events
 * 2) a fn that returns the values.
 *
 * @returns {Function[]} 2 functions, as described above.
 */
function recordTime() {
  const data = {};

  return [
    (name, id, status, timeTaken) => {
      // the callback
      data[name] = data[name] || [];
      data[name].push({ id, status, timeTaken });
    },
    () => {
      return data;
    } // the one that returns the stored data
  ];
}

/** A simple logging callback
 *
 * @param {string} name Event name
 * @param {number} id An id if the event is associated with id else '<>'
 * @param {boolean} state Whether the fetch succeeded
 * @param {number} timeTaken Time(in ms) that the fetch took.
 */
function logTime(name, id, state, timeTaken) {
  console.log(`${name}:${id} - ${state} with ${timeTaken.toFixed(2)} ms`);
}

/** A Recursive Fetch Function that returns a
 * promise which rejects after {n} tries.
 *
 * @param  {string} name Name under which the metric is saved.
 * @param  {number} id The Object id if the object has one, or '<>'
 * @param  {Function} callback callback to call when fetch is done
 * @param {string} url The Url to hit
 * @param {object} options Options to fetch
 * @param {number} maxRetry Retry Count
 * @returns {Promise} That resolves to data or error.
 */
async function fetchRetry(name, id, callback, url, options, maxRetry = 3) {
  const startTime = performance.now();
  let tryIteration = 0;
  let succeeded = false;
  let data = null;
  const errorArr = [];
  while (!succeeded && tryIteration < maxRetry) {
    try {
      // eslint-disable-next-line no-await-in-loop
      const response = await fetch(url, options);
      if (response.ok) {
        succeeded = true;
        // eslint-disable-next-line no-await-in-loop
        data = await response.json();
      }
    } catch (error) {
      errorArr.push(error);
    }
    tryIteration += 1;
  }

  if (errorArr.length === maxRetry) {
    // Failed all times
    callback(name, id, false, performance.now() - startTime);
    throw new Error(
      `Failed to retrieve ${url} with error: ${errorArr[maxRetry - 1].message}`
    );
  } else {
    callback(name, id, true, performance.now() - startTime);
    return data;
  }
}

/** A function that fetches, used for embedding CORS headers and also offer
 * a callback fn for Rollback.
 *
 * @param {string} method HTTP Method
 * @param {string} url The Url to hit
 * @param {Function} onFail A function that will be called on Failure
 * @param {Function} onSuccess A function that will be called on success
 * @param {object} body the object to send as body.
 * @returns {Promise} returns true on success.
 */
async function fetchWithRollback(method, url, onFail, onSuccess, body = null) {
  try {
    const options = {
      method: method,
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'include',
      referrer: 'no-referrer'
    };  
    if (body) { 
      options.body = JSON.stringify(body);
      options.headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      };
    }
    
    const responseStream = await fetch(url, options);
    await responseStream.json();
    if (responseStream.status !== 200) onFail();
    else {
      onSuccess();
      return true;
    }
  } catch (error) {
    console.log(error)
    onFail();
    return null;
  }
}

/** A simple fetch that uses CORS and converts to json.
 * Returns Null on failure
 *
 * @param {string} name Type of object
 * @param {string} url the url for the object
 * @returns {Promise} the resolves to the fetched data or rejects with an error
 */
async function fetchData(name, url) {
  try {
    const requestObject = await fetch(url, {
      credentials: 'include'
    });
    return await requestObject.json();
  } catch (error) {
    return null;
  }
}

/**
 * A Post request with json body
 *
 * @param {string} url the url for the object
 * @param {object} body the body of the request, must bw object that can be dumped to JSON
 * @returns {Promise} the resolves to the fetched data or rejects with an error
 */
async function postJson(url, body) {
  const requestObject = await fetch(url, {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body)
  });
  if (requestObject.ok) {
    return await requestObject.json();
  } else {
    throw requestObject
  }
}

export {
  timedFetch,
  logTime,
  recordTime,
  fetchRetry,
  fetchWithRollback,
  fetchData,
  postJson,
};
