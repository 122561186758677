import styled from 'styled-components';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faQuestion,
  faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';

import { SimpleTooltip } from '../utils/simpleToolTip';
import { getMRR, getRenewalDate } from './watchListTable';

dayjs.extend(customParseFormat);
dayjs.extend(utc);

const SimpleStatsRowStyle = styled.div`
  height: 100%;
  table {
    width: 100%;
    height: 100%;
    tr {
      height: 100%;
      td {
        font-size: 0.85rem;
        vertical-align: middle;
        height: 100%;

        span {
          display: inline-block;
          padding: 7px;
          border: thin solid lightgray;
          border-radius: 5px;
        }
      }
    }
  }
`;

/** A Presentational Component rendering the top Stats Row
 *
 * @returns {object} React Component
 */
function SimpleStatsRow({ groupsDetails }) {
  const totalAccounts = groupsDetails.length;
  let totalMRR = 0;
  let totalOpps = 0;
  let upcomingRenewals = 0;

  groupsDetails.forEach(row => {
    if (!row.isLoaded) return;
    if (!isNaN(getMRR(row))) {
      // Total MRR
      totalMRR += getMRR(row);
    }
    // Opp Count
    const oppCount = parseInt(row.openOpportunities.count[0], 10);
    if (!isNaN(oppCount)) totalOpps += oppCount;

    const today = dayjs()
      .utc()
      .local()
      .startOf('day');
    const renewalDate = dayjs(getRenewalDate(row), 'YYYY-MM-DD');

    if (
      today.add(30, 'days').isAfter(renewalDate) &&
      renewalDate.isAfter(today)
    ) {
      upcomingRenewals += 1;
    }
  });

  const data = [
    ['Total Accounts', totalAccounts],
    ['Total MRR($)', `$${totalMRR.toFixed(2)}`],
    ['Upcoming Renewals (in 30 days)', upcomingRenewals],
    ['Total Opps', totalOpps],
    ['Total Open Tickets', 'Pending']
  ];

  const renderedData = data.map(val => (
    <td key={val[0]}>
      <span>
        <b>{val[0]}</b>
        {': '}
        {val[1]}
      </span>
    </td>
  ));

  return (
    <SimpleStatsRowStyle>
      <table>
        <tbody>
          <tr>{renderedData}</tr>
        </tbody>
      </table>
    </SimpleStatsRowStyle>
  );
}

/** A simple presentational Component for showing add-groups UI
 *
 * @returns {object} React Component
 */
function AddGroupToWatchlistWidget({ addGroups }) {
  const [groupList, setGroupList] = useState([]);
  const [isDataValid, setIsDataValid] = useState(true);

  const onSubmit = event => {
    if (!isDataValid) {
      alert('Please enter Valid, Comma Separated Group Ids');
    } else if (groupList.length === 0) {
      alert('Please enter at least one Group Id');
    } else {
      addGroups(groupList);
    }
    event.preventDefault();
  };

  const validateData = event => {
    let valid = true;
    const rawGroupIds = event.target.value.split(',').map(val => +val.trim());
    const groupIds = rawGroupIds.filter(val => val !== '' && !isNaN(val));

    rawGroupIds.every(elem => {
      if (isNaN(elem)) {
        setIsDataValid(false);
        setGroupList([]);
        valid = false;
        return false;
      }
      return true;
    });
    setGroupList(groupIds);
    setIsDataValid(valid);
  };
  return (
    <Form inline>
      <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
        <Label>
          <b style={{ fontSize: '0.85rem' }}>Add Groups</b>
          <FontAwesomeIcon
            icon={faQuestion}
            style={{ color: 'blue' }}
            id="add-to-watchlist"
          />
          <SimpleTooltip placement="right" target="add-to-watchlist">
            Enter Group Ids Seperated by Commas.
          </SimpleTooltip>
        </Label>
      </FormGroup>
      <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
        <Input type="text" onChange={validateData} />
      </FormGroup>
      {!isDataValid && (
        <>
          <FontAwesomeIcon
            id="add-to-watchlist-notif"
            style={{
              color: 'red',
              marginLeft: '-2.5rem',
              marginRight: '2rem'
            }}
            icon={faExclamationTriangle}
          />
          <SimpleTooltip placement="bottom" target="add-to-watchlist-notif">
            Enter Group Ids Seperated by Commas.
          </SimpleTooltip>
        </>
      )}
      <Button type="submit" onClick={onSubmit}>
        Submit
      </Button>
    </Form>
  );
}

SimpleStatsRow.propTypes = {
  groupsDetails: PropTypes.array
};

AddGroupToWatchlistWidget.propTypes = {
  addGroups: PropTypes.func
};

export { SimpleStatsRow, AddGroupToWatchlistWidget };
