import React from 'react';
import Proptype from 'prop-types';
import { mapValues, last, transform } from 'lodash-es';
import { Table, Col } from 'reactstrap';
import { DenseTable, tieredPricingAccordionStyling } from '../utils/_styles';
import config from '../../config';
import Collapsible from 'react-collapsible';

class SubscriptionWidget extends React.Component {
  static coalesce(subscriptionStatuses) {
    const v3Plans = {
      Browser: ['automate_group_plan', 'live_testing_group_plan'],
      App: ['app_automate_group_plan', 'app_live_testing_group_plan'],
      Visual: ['percy_group_plan'],
      'App Visual': ['app_percy_group_plan'],
      Observability: ['test_observability_group_plan'],
      Accessibility: ['accessibility_testing_group_plan'],
      'Test Management': ['test_management_group_plan'],
      'Code Quality Cloud': ['code_quality_cloud_group_plan'],
      'Code Quality Self Managed': ['code_quality_self_managed_group_plan'],
      'Low Code Automation': ['low_code_automation_group_plan'],
      'App Accessibility': ['app_accessibility_testing_group_plan'],
      'Automate Turboscale': ['automate_turboscale_group_plan'],
    };

    let v4Plans = {
      Automate: ['automate_group_plan'],
      Live: ['live_testing_group_plan'],
      'App Automate': ['app_automate_group_plan'],
      'App Live': ['app_live_testing_group_plan'],
      Visual: ['percy_group_plan'],
      'App Visual': ['app_percy_group_plan'],
      Observability: ['test_observability_group_plan'],
      Accessibility: ['accessibility_testing_group_plan'],
      'Test Management': ['test_management_group_plan'],
      'Code Quality Cloud': ['code_quality_cloud_group_plan'],
      'Code Quality Self Managed': ['code_quality_self_managed_group_plan'],
      'Low Code Automation': ['low_code_automation_group_plan'],
      'App Accessibility': ['app_accessibility_testing_group_plan'],
      'Automate Turboscale': ['automate_turboscale_group_plan'],
    };

    let plansToUse = v3Plans;
    for (let groupPlan of Object.values(subscriptionStatuses)) {
      if (groupPlan.pricingVersion > 3) {
        plansToUse = v4Plans;
        break;
      }
    }

    // write merge code here, it takes live plans by default for v3 family products
    return mapValues(plansToUse, planArray => {
      // to find active plan in the family
      let activeGPlan = null;
      // merge U&P - if both group plans are active, merge their basePlans if EFTs
      let users = null;
      let parallels = null;
      // loop groupPlans
      planArray.forEach(gPlan => {
        // prefer active Group Plan
        let planName = gPlan
          .slice(0, gPlan.indexOf('_group_plan'))
          .replace(/_/g, ' ');
        planName = planName[0].toUpperCase() + planName.slice(1);
        let groupPlan = subscriptionStatuses[gPlan];
        if (groupPlan && groupPlan.status === 'Active') {
          // overwrite u&p if next plan is active
          users = users
            ? `${users} || ${groupPlan.noOfUsers} ${planName} Users`
            : `${groupPlan.noOfUsers} ${planName} Users`;
          parallels = parallels
            ? `${parallels} || (${planName}) ${groupPlan.noOfParallels} Parallels`
            : `${groupPlan.noOfParallels} Parallels`;
          groupPlan.userString = users;
          groupPlan.parallelString = parallels;
          activeGPlan = groupPlan;
        } else if (
          groupPlan &&
          groupPlan.status === 'Extended Free Trial' &&
          groupPlan.basePlan &&
          groupPlan.basePlan.status === 'Active'
        ) {
          // overwrite u&p if next baseplan is active
          users = users
            ? `${users} || ${groupPlan.noOfUsers} ${planName} Users`
            : `${groupPlan.basePlan.noOfUsers} ${planName} Users`;
          parallels = parallels
            ? `${parallels} || ${groupPlan.noOfParallels} ${planName} Parallels`
            : `${groupPlan.basePlan.noOfParallels} ${planName} Parallels`;
          groupPlan.basePlan.userString = users;
          groupPlan.basePlan.parallelString = parallels;
          activeGPlan = groupPlan;
        }
      });
      // none active, show live plan
      if (!activeGPlan) activeGPlan = subscriptionStatuses[last(planArray)];
      return activeGPlan;
    });
  }

  presentPricing(charges = {}, basePlanCharges = {}) {
    const rows = [];
    for (const key of Object.keys(charges)) {
      if (charges[key] > 0 || basePlanCharges[key] > 0) {
        rows.push(
          <tr key={key}>
            <td>{config.pricingMapping[key]}</td>
            <td>${charges[key].toFixed(2)}</td>
            {basePlanCharges[key] && (
              <td>${basePlanCharges[key].toFixed(2)}</td>
            )}
          </tr>
        );
      }
    }
    return rows;
  }

  columnMapper(data, planFamily) {
    if (planFamily.toLowerCase() == 'observability') {
      return {
        Status: data.status,
        'Pricing Version': data.pricingVersion,
        Plan: `${data.planName}(${data.planId})`,
        'Enterprise Plan': data.isEnterprise ? 'Yes' : 'No',
        [data.status === 'Sponsored' ? 'WORTH' : 'MRR']: `$${data.mrr}`,
        'Renewal Date': data.renewalDate,
        'Customer Since': data.customerSince,
        Users: data.userString || data.noOfUsers,
        Parallels: data.parallelString || data.noOfParallels,
        [data.deviceFeaturesDuration
          ? `Device Features used in last ${data.deviceFeaturesDuration} days`
          : 'Device Features']: data.deviceFeatures,
        'Is Bundled Plan': data.isBundledPlan ? 'Yes' : 'No'
      };
    }
    return {
      Status: data.status,
      'Pricing Version': data.pricingVersion,
      Plan: `${data.planName}(${data.planId})`,
      'Enterprise Plan': data.isEnterprise ? 'Yes' : 'No',
      [data.status === 'Sponsored' ? 'WORTH' : 'MRR']: `$${data.mrr}`,
      'Renewal Date': data.renewalDate,
      'Customer Since': data.customerSince,
      Users: data.userString || data.noOfUsers,
      Parallels: data.parallelString || data.noOfParallels,
      [data.deviceFeaturesDuration
        ? `Device Features used in last ${data.deviceFeaturesDuration} days`
        : 'Device Features']: data.deviceFeatures
    };
  }

  presentGroupPlan(data, planFamily) {
    const rows = [];
    const mapping = this.columnMapper(data, planFamily);
    // basePlan at all places is used to represent the underlying plan on which eft is provided
    let basePlanMap = null;
    let basePlanCharges = {};
    if (data.basePlan && data.basePlan.status) {
      basePlanMap = this.columnMapper(data.basePlan, planFamily);
      basePlanCharges = data.basePlan.charges;
    }
    for (let key in mapping) {
      rows.push(
        <tr key={key}>
          <td>{key}</td>
          <td>{mapping[key]}</td>
          {basePlanMap && <td>{basePlanMap[key]}</td>}
        </tr>
      );
    }
    const pricingRows = this.presentPricing(data.charges, basePlanCharges);
    rows.push(...pricingRows);
    return rows;
  }

  presentTieredPlan(data) {
    const tiersMapping = [];
    const rows = [];
    if (data.charges.tiers.length <= 0) return rows;
    data.charges.tiers.forEach(tierItem => {
      const tierObject = { tier: 0, units: 0, unit_price: 0 };
      tierObject.tier = tierItem.tier;
      tierObject.units = tierItem.ending_unit - tierItem.starting_unit + 1;
      tierObject.unit_price = tierItem.price;
      tiersMapping.push(tierObject);
    });
    for (let tier of tiersMapping) {
      rows.push(
        <tr key={`tier_${tier.tier}`}>
          <td key={`tier`}>{tier.tier}</td>
          <td key={`units`}>{tier.units}</td>
          <td key={`unit_price`}>{tier.unit_price}</td>
        </tr>
      );
    }
    return rows;
  }

  render() {
    if (!this.props.subscriptionStatuses)
      return <div>Loading Subscription Status Failed.</div>;
    const subscriptionStatuses = SubscriptionWidget.coalesce(
      this.props.subscriptionStatuses
    );
    if (
      (!subscriptionStatuses.App && !subscriptionStatuses['App Live']) ||
      (!subscriptionStatuses.Browser && !subscriptionStatuses['Automate'])
    )
      return <div>Loading Subscription Status Failed.</div>;

    return (
      <>
        <Col>
          <h2>Subscription</h2>
          {transform(
            subscriptionStatuses,
            (result, data, planFamily) => {
              result.push(
                <div key={planFamily}>
                  <h6>{planFamily} Family</h6>
                  <DenseTable>
                    <Table striped bordered>
                      <tbody>{this.presentGroupPlan(data, planFamily)}</tbody>
                    </Table>
                  </DenseTable>
                  {data.pricingVersion == 4 && data.isTiered && (
                    <Collapsible
                      trigger="Tiered Pricing Breakup ↓"
                      triggerStyle={tieredPricingAccordionStyling}
                      triggerWhenOpen={'Tiered Pricing Breakup ↑'}
                    >
                      <DenseTable>
                        <Table striped bordered>
                          <thead>
                            <tr>
                              <th>Tier</th>
                              <th>Units</th>
                              <th>Per Unit Price ($)</th>
                            </tr>
                          </thead>
                          <tbody>{this.presentTieredPlan(data)}</tbody>
                        </Table>
                      </DenseTable>
                    </Collapsible>
                  )}
                </div>
              );
            },
            []
          )}
        </Col>
      </>
    );
  }
}

SubscriptionWidget.propTypes = {
  subscriptionStatuses: Proptype.object
};

export default SubscriptionWidget;
