import React, { Fragment } from 'react';
import styled from 'styled-components';
import { mapValues, map, omitBy, get } from 'lodash-es';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon as FA5 } from '@fortawesome/react-fontawesome';
import {
  faExclamationTriangle,
  faMinusCircle,
  faShareSquare,
  faTimesCircle,
  faCog
} from '@fortawesome/free-solid-svg-icons';

import config from '../../config';
import { SimpleTooltip, getTooltip } from '../utils/simpleToolTip';

const FreeStyled = styled.span`
  color: crimson;
  text-decoration: line-through;
`;

const FreeStyledGreen = styled.span`
  color: green;
`;
const isEmpty = hash => (hash ? Object.keys(hash).length === 0 : true);
const isGroupPlanFree = gp => gp.planName === 'Free';

const productAbrevMapping = {
  live_testing_group_plan: { name: 'Live', abrev: 'L :' },
  automate_group_plan: { name: 'Automate', abrev: 'A :' },
  app_live_testing_group_plan: { name: 'App Live', abrev: 'AL:' },
  app_automate_group_plan: { name: 'App Automate', abrev: 'AA:' },
  percy_group_plan: { name: 'Percy', abrev: 'P:' },
  app_percy_group_plan: { name: 'App Percy', abrev: 'AP:' },
  test_observability_group_plan: { name: 'Observability', abrev: 'O:' },
  accessibility_testing_group_plan: { name: 'Accessibility', abrev: 'AS:' },
  test_management_group_plan: { name: 'Test Management', abrev: 'TM:' },
  code_quality_cloud_group_plan: { name: 'Code Quality Cloud', abrev: 'CC:' },
  code_quality_self_managed_group_plan: { name: 'Code Quality Self Managed', abrev: 'CSM:' },
  low_code_automation_group_plan: { name: 'Low Code Automation', abrev: 'LCA:' },
  app_accessibility_testing_group_plan: { name: 'App Accessibility', abrev: 'AAS:' },
  automate_turboscale_group_plan: { name: 'Automate Turboscale', abrev: 'AT:' },
};

/** Get A cumulative RenewalDate for all Group Plans
 *
 * @param {object} originalRow the Data from the fetch
 * @returns {string} The Renewal Date
 */
function getMRR(originalRow) {
  const { subscriptionStatuses: subStatus } = originalRow;

  if (!subStatus || isEmpty(subStatus)) return '-';

  const browserPlan =
    subStatus.automate_group_plan &&
    isGroupPlanFree(subStatus.automate_group_plan)
      ? subStatus.live_testing_group_plan
      : subStatus.automate_group_plan;
  const appPlan =
    subStatus.app_automate_group_plan &&
    isGroupPlanFree(subStatus.app_automate_group_plan)
      ? subStatus.app_live_testing_group_plan
      : subStatus.app_automate_group_plan;
  let visualPlan = subStatus.percy_group_plan;
  let appVisualPlan = subStatus.app_percy_group_plan;
  let observabilityPlan = subStatus.test_observability_group_plan;
  let accessibilityPlan = subStatus.accessibility_testing_group_plan;
  let testManagementPlan = subStatus.test_management_group_plan;
  let codeQualityCloudPlan = subStatus.code_quality_cloud_group_plan;
  let codeQualitySelfManagedPlan = subStatus.code_quality_self_managed_group_plan;
  let lowCodeAutomationPlan = subStatus.low_code_automation_group_plan;
  let appAccessibilityPlan = subStatus.app_accessibility_testing_group_plan;
  let automateTurboscalePlan = subStatus.automate_turboscale_group_plan;

  const browserMrr =
    browserPlan && browserPlan.status !== 'Cancelled' ? browserPlan.mrr : 0;
  const appPlanMrr =
    appPlan && appPlan.status !== 'Cancelled' ? appPlan.mrr : 0;
  const visualPlanMrr =
    visualPlan && visualPlan.status !== 'Cancelled' ? visualPlan.mrr : 0;
  const appVisualPlanMrr =
    appVisualPlan && appVisualPlan.status !== 'Cancelled'
      ? appVisualPlan.mrr
      : 0;
  const observabilityPlanMrr =
    observabilityPlan && observabilityPlan.status !== 'Cancelled'
      ? observabilityPlan.mrr
      : 0;
  const accessibilityPlanMrr =
    accessibilityPlan && accessibilityPlan.status !== 'Cancelled'
      ? accessibilityPlan.mrr
      : 0;
  const testManagementPlanMrr =
    testManagementPlan && testManagementPlan.status !== 'Cancelled'
      ? testManagementPlan.mrr
      : 0;
  const codeQualityCloudPlanMrr =
    codeQualityCloudPlan && codeQualityCloudPlan.status !== 'Cancelled'
      ? codeQualityCloudPlan.mrr
      : 0;
  const codeQualitySelfManagedPlanMrr =
    codeQualitySelfManagedPlan && codeQualitySelfManagedPlan.status !== 'Cancelled'
      ? codeQualitySelfManagedPlan.mrr
      : 0;  
  const lowCodeAutomationPlanMrr =
    lowCodeAutomationPlan && lowCodeAutomationPlan.status !== 'Cancelled'
      ? lowCodeAutomationPlan.mrr
      : 0;
  const appAccessibilityPlanMrr =
    appAccessibilityPlan && appAccessibilityPlan.status !== 'Cancelled'
      ? appAccessibilityPlan.mrr
      : 0;
  const automateTurboscalePlanMrr =
    automateTurboscalePlan && automateTurboscalePlan.status !== 'Cancelled'
      ? automateTurboscalePlan.mrr
      : 0;

  const mrr =
    browserMrr +
    appPlanMrr +
    visualPlanMrr +
    appVisualPlanMrr +
    observabilityPlanMrr +
    accessibilityPlanMrr +
    testManagementPlanMrr + 
    codeQualityCloudPlanMrr + 
    codeQualitySelfManagedPlanMrr + 
    lowCodeAutomationPlanMrr + 
    appAccessibilityPlanMrr + 
    automateTurboscalePlanMrr;

  if (isNaN(mrr)) return 0;
  return mrr;
}

/** Get A cumulative RenewalDate for all Group Plans
 *
 * @param {object} originalRow the Data from the fetch
 * @returns {string} The Renewal Date
 */
function getRenewalDate(originalRow) {
  const { subscriptionStatuses: subStatus } = originalRow;

  if (isEmpty(subStatus)) {
    return '-';
  }

  const result = Object.keys(subStatus).filter(gpName => {
    const gp = subStatus[gpName];
    return (
      gp.status === 'Active' &&
      gp.renewalDate &&
      gp.renewalDate !== '0000-00-00' &&
      gp.renewalDate !== ''
    );
  });
  if (isEmpty(result)) {
    return '-';
  }
  const maxRenewalDate = result
    .map(x => subStatus[x].renewalDate)
    .reduce(function(a, b) {
      return Date.parse(a) > Date.parse(b) ? a : b;
    });
  return maxRenewalDate;
}

/** Convert SubscriptionStatus to the React Component
 *
 * @param {object} tableProps The tableProps
 * @returns {React.Component} the React Component for subscription Status
 */
function processSubscriptionStatus(tableProps) {
  const originalRow = tableProps.cell.row.original;
  let { subscriptionStatuses: subStatus } = originalRow;
  const result = [];

  const convertUnlimited = val => (val === 'Unlimited' ? '∞' : val);
  const handleUndefined = val => (val === undefined ? '-' : val);
  const handleNull = val => (val === null ? '-' : val);
  const renderCount = val => convertUnlimited(handleUndefined(handleNull(val)));
  const addFreePlanIndicator = gp => {
    const id = `dfp-${gp.planId}-${Math.round(Math.random() * 1000)}`;
    if (isEmpty(gp)) return null;
    if (isGroupPlanFree(gp)) {
      return getTooltip(id, <FreeStyled>$</FreeStyled>, 'Free Plan');
    } else {
      return getTooltip(
        id,
        <FreeStyledGreen>$</FreeStyledGreen>,
        `Plan ID: ${gp.planId}, Status: ${gp.status}, Plan Name: ${gp.planName}, Pricing Version: ${gp.pricingVersion}, Renewal Date: ${gp.renewalDate} `
      );
    }
  };
  Object.keys(subStatus).forEach(groupPlan => {
    if (groupPlan in productAbrevMapping) {
      const gp = subStatus[groupPlan];
      result.push(
        <span
          key={groupPlan}
          className={`${gp.isExpired ? 'expired' : ''} subStatusEntry`}
        >
          <span>
            {isEmpty(gp) && <FA5 icon={faTimesCircle} />}
            <b>
              {productAbrevMapping[groupPlan].abrev}
              {`${renderCount(gp.noOfParallels)}P/${renderCount(
                gp.noOfUsers
              )}U`}
            </b>
            {gp ? addFreePlanIndicator(gp) : ''}
            {!isEmpty(gp) &&
              `(${convertUnlimited(gp.noOfRegisteredUsers)}\u00A0Users)`}
          </span>
        </span>
      );
      result.push(<br key={`${groupPlan}-br`} />);
    }
  });
  return result;
}

/** Give the JSX for Account Column
 *
 * @param {object} tableProps of the Cell
 *
 * @returns {React.Component} the React Component for Account
 */
function giveAccount(tableProps) {
  const originalRow = tableProps.cell.row.original;
  let {
    sfdcAccountName: accountName,
    domainName: domain,
    groupId
  } = originalRow;
  const { sfdcUrl, sfdcUrlError } = originalRow.openOpportunities;
  let domainPart = domain;
  let sfdcPart;

  if (!domainPart || domainPart === '-') {
    domainPart = (
      <span>
        <span id={`noDomain-${groupId}`}>
          <FA5 icon={faExclamationTriangle} />
          --
        </span>
        <SimpleTooltip
          placement="right"
          target={`noDomain-${groupId}`}
          autohide={false}
        >
          No Domain Name found. (Likely Caused by no Owner of Group.)
        </SimpleTooltip>
      </span>
    );
  }
  if (accountName === '') {
    sfdcPart = (
      <span>
        <span id={`noSfdcURL-${groupId}`}>
          <FA5 icon={faExclamationTriangle} />
          --
        </span>
        <SimpleTooltip
          placement="right"
          target={`noSfdcURL-${groupId}`}
          autohide={false}
        >
          No Account Name Found.
        </SimpleTooltip>
      </span>
    );
  } else if (sfdcUrl !== '') {
    sfdcPart = (
      <a href={sfdcUrl} target="_blank" rel="noopener noreferrer">
        <FA5 icon={faShareSquare} />
        {accountName}
      </a>
    );
  } else {
    sfdcPart = (
      <span>
        <span style={{ borderBottom: '1px dotted #ff0000', padding: '1px' }}>
          <span style={{ borderBottom: '1px dotted #ff0000' }}>
            <span href="#" id={`sfdcNoUrl-${groupId}`}>
              {accountName}
            </span>
            .
          </span>
        </span>
        <SimpleTooltip
          placement="right"
          target={`sfdcNoUrl-${groupId}`}
          autohide={false}
        >
          <a href="www.google.com" target="_blank" rel="noopener noreferrer">
            {sfdcUrlError && sfdcUrlError.trim() !== ''
              ? sfdcUrlError
              : 'No SFDC Data could be found on BigQuery.'}
          </a>
        </SimpleTooltip>
      </span>
    );
  }
  const groupPart = <Link to={`/group/${groupId}`}>{groupId}</Link>;
  const adminLink = (
    <a
      href={`https://www.browserstack.com/admin/user_stats?q=${groupId}&column_selected=Group_ID&commit=Go`}
      target="_blank"
    >
      {'Admin Page'}
    </a>
  );
  return (
    <span>
      {' '}
      {domainPart} <br /> {sfdcPart} <br /> {groupPart} <br /> {adminLink}{' '}
    </span>
  );
}

/** Returns the UI for OpenOpps
 *
 * @param {object} tableProps The tableprops
 * @returns {React.Component} The count of open Opps with a link to SFDC.
 */
function openOpportunities(tableProps) {
  const originalRow = tableProps.cell.row.original;
  let { openOpportunities: openOpps, groupId } = originalRow;
  let error = openOpps.error || false;

  return error ? (
    <>
      {getTooltip(
        `openOpps-${groupId}`,
        <FA5 icon={faExclamationTriangle} />,
        error
      )}
    </>
  ) : (
    <Fragment>
      <a href={openOpps.sfdcUrl} target="_blank" rel="noopener noreferrer">
        {openOpps.count} <FA5 icon={faShareSquare} />
      </a>
      <br />
    </Fragment>
  );
}

const LinkStyled = styled.a`
  border-radius: 3px;
  border: thin solid grey;
  margin-right: 3px;
  display: inline-block;
  margin-bottom: 3px;
  padding: 1px 5px;
`;

/** Gets the list of looker links for a given row
 *
 * @param {object} props The rows props from the table.
 * @returns {Array} List of React Fragments with looker links for a group.
 */
function getLookerLinks(props) {
  const { groupId } = props.cell.row.original;
  const links = config.getLookerLinks(groupId);
  const linkOrder = [
    { name: 'Live', color: '#ffbc42' },
    { name: 'App Live', color: 'cyan' },
    { name: 'Percy', color: '#00ff9f' },
    { name: 'App Percy', color: '#F49E82' },
    { name: 'Automate One', color: '#32a852' },
    { name: 'Selenium', color: '#fff346' },
    { name: 'Activity', color: '#e5ff9e' },
    { name: 'App Automate', color: '#ffcdc2' },
    { name: 'Cypress', color: '#ffddee' },
    { name: 'JS', color: '#f7e3df' },
    { name: 'Browser Stats - Automate', color: 'bisque' },
    { name: 'Browser Stats - Cypress', color: '#ffaa44' },
    { name: 'Browser Stats - JS', color: '#fcb5a7' }
  ];

  const l = linkOrder.map(product => (
    <LinkStyled
      key={product.name}
      href={links[product.name]}
      target="_blank"
      rel="noopener noreferrer"
      style={{ backgroundColor: product.color }}
    >
      {product.name}
    </LinkStyled>
  ));

  return (
    <div style={{ width: '159px', display: 'flex', flexWrap: 'wrap' }}>{l}</div>
  );
}

const Badge = styled.span`
  .ticket-badge {
    display: inline-block;
    padding: 0.35em 0.54em;
    font-size: 100%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 1.25rem;
    margin-bottom: 0.3rem;
  }
`;

/**
 * Get the count for the various types of freshdesk tickets.
 *
 * @param {object} cellProps The cell Props given by react table
 * @returns {object} rendered component
 */
function getFreshdeskTickets(cellProps) {
  const {
    cell: {
      value: tickets,
      row: {
        original: { groupId }
      }
    }
  } = cellProps;
  const colorMap = {
    Open: { bg: '#fe9102', fg: '' },
    Pending: { bg: '#ffd004', fg: '' },
    Resolved: { bg: '#04d603', fg: '' },
    Closed: { bg: '#c0c0c0', fg: '' },
    'On-Hold': { bg: '#fb0200', fg: '#ffffff' },
    'Pending with Engineering': { bg: '#bb4210', fg: '#ffffff' },
    'Pending 10 Days': { bg: '#EFFDCB', fg: '#0056b3' }
  };

  // count the tickets in each categories and filter only those who have tickets
  const count = omitBy(
    mapValues(tickets, t => t.length),
    x => x === 0
  );

  // Render the links to be shown inside the tooltip
  const renderedLinks = mapValues(tickets, stageTickets =>
    map(stageTickets, ticket => (
      <a
        key={ticket.tid}
        href={ticket.ticketUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        #{ticket.tid}
      </a>
    ))
  );

  const rendered = map(count, (val, i) => {
    const cm = colorMap[i] || { bg: '#dddddd', fg: '#222222' };
    return (
      <Fragment key={i}>
        <span
          id={`ticket_tt_${groupId}`}
          className="ticket-badge"
          style={{ backgroundColor: cm.bg, color: cm.fg }}
        >
          {i}: {val}
        </span>
        <SimpleTooltip
          placement="right"
          target={`ticket_tt_${groupId}`}
          autohide={false}
        >
          {renderedLinks[i]}
        </SimpleTooltip>
        <br />
      </Fragment>
    );
  });

  return (
    <>
      {' '}
      <Badge>{rendered}</Badge>
    </>
  );
}

export {
  getMRR,
  getRenewalDate,
  giveAccount,
  processSubscriptionStatus,
  openOpportunities,
  getLookerLinks,
  getFreshdeskTickets
};
