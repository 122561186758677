import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

const sentryLimiter = {};

if (
  process.env.NODE_ENV !== 'development' &&
  process.env.NODE_ENV !== 'staging'
) {
  Sentry.init({
    dsn:
      'https://60757a7172b04c699e5fb3f436b5e89f@o529436.ingest.sentry.io/5647776',
    beforeSend(event) {
      if (event.exception && event.exception.value in sentryLimiter) {
        return false;
      }

      if (event.exception && event.exception.value)
        sentryLimiter[event.message.value] = true;

      setTimeout(() => {
        delete sentryLimiter[event.message];
      }, 10 * 60 * 1000);

      return event;
    }
  });
} else {
  document.title = 'Hawkeye';
  console.log('Sentry is Disabled');
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
