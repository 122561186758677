import React from 'react';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col
} from 'reactstrap';
import classnames from 'classnames';
import PropTypes from 'prop-types';

class MainTab extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    const activeTab = this.props.initialActiveTabName;
    this.state = {
      activeTab: activeTab || Object.keys(this.props.tabs)[0]
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.props.onTabChange(tab);
      this.setState({
        activeTab: tab
      });
    }
  }

  getNavItems() {
    if (this.props.tabs) {
      return Object.keys(this.props.tabs).map(tabName => {
        return (
          <NavItem key={tabName}>
            <NavLink
              className={classnames({
                active: this.state.activeTab === tabName
              })}
              onClick={() => {
                this.toggle(tabName);
              }}
            >
              {tabName}
            </NavLink>
          </NavItem>
        );
      });
    }
    return [];
  }

  getTabContents() {
    if (this.props.tabs) {
      return Object.keys(this.props.tabs).map(tabName => {
        return (
          <TabPane key={tabName} tabId={tabName}>
            <Row>
              <Col sm="12">{this.props.tabs[tabName]}</Col>
            </Row>
          </TabPane>
        );
      });
    }
    return [];
  }

  render() {
    return (
      <div>
        <Nav tabs>{this.getNavItems()}</Nav>
        <TabContent activeTab={this.state.activeTab}>
          {this.getTabContents()}
        </TabContent>
      </div>
    );
  }
}

MainTab.propTypes = {
  tabs: PropTypes.any,
  initialActiveTabName: PropTypes.string
};

export default MainTab;
