import React from 'react';
import Proptype from 'prop-types';
import { mapValues, values } from 'lodash-es';
import { Table, Col } from 'reactstrap';
import { DenseTable } from '../utils/_styles';

class UserLocationWidget extends React.Component {
  render() {
    const { userLocation } = this.props;
    if (!userLocation) return <div>Loading User Location Failed.</div>;

    const userLocationRendered = values(
      mapValues(userLocation.countries, (countryData, country) => {
        const citiesRendered = values(
          mapValues(countryData.cities, (cityUserCount, cityName) => {
            return (
              <React.Fragment key={cityName}>
                {' '}
                {cityName}({cityUserCount}){' '}
              </React.Fragment>
            );
          })
        );
        return (
          <React.Fragment key={country}>
            <tr>
              <td>
                {country}({countryData.userCount})
              </td>
              <td>{citiesRendered}</td>
            </tr>
          </React.Fragment>
        );
      })
    );
    const { country: ownerCountry, city: ownerCity } = userLocation.owner;
    return (
      <>
        <Col>
          <h2>{'\u00A0'}</h2>
          {/* For aligning to the subscription status */}
          <h2>User Location</h2>
          <DenseTable>
            <Table striped bordered>
              <tbody>
                <tr>
                  <th>Owner</th>
                  <td>
                    {(
                      <>
                        {ownerCity}
                        <br />
                        {ownerCountry}
                      </>
                    ) || '--'}
                  </td>
                </tr>
                <tr>
                  <th>All Users</th>
                </tr>
                {userLocationRendered}
              </tbody>
            </Table>
          </DenseTable>
        </Col>
      </>
    );
  }
}

UserLocationWidget.propTypes = {
  userLocation: Proptype.object
};

export default UserLocationWidget;
