import React from 'react';
import Proptype from 'prop-types';
import { values } from 'lodash-es';
import { Col } from 'reactstrap';
import './modal.css';

import { DenseTable } from '../utils/_styles';
import CustomTable from '../utils/customTable';

const DocSnippet = "or support for preferred automation frameworks / languages - {{mention from docs read}}";
const PricingSnippet = "I can also suggest plans or customize one that best suit your test requirements.";
class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDocSnippet: false,
      showPricingSnippet: false,
      selectedSubject: this.props.templateData.template.subject[0] || "N/A"
    };


  }

  resetState = () => {
    this.setState({
      showDocSnippet: false,
      showPricingSnippet: false,
      selectedSubject: this.props.templateData.template.subject[0] || "N/A"
    });
  }

  // componentDidMount() {
  //   if (this.props.templateData.template.subject.length) {
  //     this.setState({
  //       selectedSubject: this.props.templateData.template.subject[0]
  //     });
  //   }
  // }
  componentDidUpdate(prevProps, prevState) {
    if(this.props.email!== prevProps.email) {
         this.resetState();
    }
  }

  handleSubjectChange = (event) => {
    this.setState({
      selectedSubject: event.target.value
    })
  }

  handleDocCbxChange = (newValue) => {
    this.setState({
      showDocSnippet: newValue
    });
  }

  handlePricingCbxChange = (newValue) => {
    this.setState({
      showPricingSnippet: newValue
    });
  }

  render()  {
    return(
      <div className={this.props.show ? "modal display-block" : "modal display-none"} onClick={this.props.handleClose}>
      <section className="modal-main" onClick={ e => { e.stopPropagation(); }}>
        <div className="lead-details block-content">
          <h5>Lead Details</h5>
          <div className="lead-details-text">Email: { this.props.email }</div>
          <div className="lead-details-text">Segment ID: { this.props.templateData.segment_id }</div>
          <div className="instructions">
            <h5>Instructions</h5>
            <ol>
              <li>Choose any 1 subject line</li>
              <li>Configure email body by choosing snippet based on data available</li>
              <li>Enter data as applicable for placeholders in curly braces {"{{..}}"}</li>
            </ol>
          </div>
          <div className="subject-lines">
            <h5>Subject Lines</h5>
            {this.props.templateData.template.subject.map((choice, index) => (
            <div className="radio">
              <input type="radio"
                name="vote"
                className="radio-btn"
                value={choice}
                key={index}
                checked={this.state.selectedSubject === choice}
                // checked={index == 0}
                onChange={ e => { this.handleSubjectChange(e) } }
              />
              <label key={index} className="radio-label">
                {choice}
              </label>
            </div>
            ))}
          </div>
          <div className="snippets">
            <h5>Snippets</h5>
            <Checkbox key={this.props.email + "1"} handleCbxChange={ this.handleDocCbxChange } label={ "DOCS : " + this.props.templateData.template.snippet.docs } />
            <Checkbox key={this.props.email + "2"} handleCbxChange={ this.handlePricingCbxChange } label={"PRICING : " +  this.props.templateData.template.snippet.pricing } />
          </div>
        </div>
        <div className="preview-section block-content">
          <h5>Preview</h5>
          <div className="email-section">
          <div className="email-subject"> Subject : { this.state.selectedSubject }</div><br/>
            <div
              className="email-body"
              dangerouslySetInnerHTML={{
                __html: this.props.templateData.template.body.replace("<span class=\'snippet\'></span>", "<span class=\'snippet\'>" +  (this.state.showDocSnippet ? this.props.templateData.template.snippet.docs : "")  +  (this.state.showPricingSnippet ? ". " + this.props.templateData.template.snippet.pricing : "")  + "</span>")
              }}>
            </div>
          </div>
        </div>
      </section>
    </div>
    );
  }
}

class Checkbox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isChecked: false,
    };
  }
  toggleChange = () => {
    this.setState({ isChecked: !this.state.isChecked }, function() {
      this.props.handleCbxChange(this.state.isChecked);
    });
  }
  render() {
    return (
      <div className="checkbox">
        <input type="checkbox"
          className="checkbox-btn"
          checked={this.state.isChecked}
          onChange={this.toggleChange}
        />
        <label className="checkbox-label">
          { this.props.label }
        </label>
      </div>

    );
  }
}


class LeadTable extends React.Component {
  constructor(props) {
    super(props);

    const columns = [
      {
        Header: 'Sr. No',
        accessor: () => 'PENDING',
        disableSorting: true,
        disableFilter: true
      },
      { Header: 'Lead', accessor: 'lead' },
      { Header: 'Group Status', accessor: 'group_status' },
      { Header: 'Lead Score', accessor: 'lead_score' },
      { Header: 'Status', accessor: 'status' },
      { Header: 'Website Activity', accessor: 'website_activity' },
      { Header: 'Product Usage', accessor: 'product_usage' },
      { Header: 'Marketing / Sales Activity', accessor: 'marketing_sales_activity' },
      { Header: 'Email Template', accessor: row => this.props.getTemplateFromEmail(row.lead).description },
    ];

    this.state = {
      columns: columns,
      isLoaded: false,
      error: null
    };

    this.addRow = this.addRow.bind(this);
  }

  addRow(newRow) {
    this.setState({
      data: [...this.state.rows, newRow]
    });
  }

  static styleRow(row) {
    if (row.unsubscribedFlag) {
      return { backgroundColor: '#ffaaaa' };
    }
    return {};
  }

  render() {
    return (
      <>
        <DenseTable>
          <CustomTable
            columns={this.state.columns}
            data={this.props.rows}
            styleRow={LeadTable.styleRow}
            showTemplateModal={this.props.showTemplateModal}
          />
        </DenseTable>
      </>
    );
  }
}

class LeadInfoWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      showDocSnippet: false,
      showPricingSnippet: false,
      templateData: {
        segment_id: null,
        template: {
          subject: [],
          body: "",
          snippet: {
            docs: null,
            pricing: null
          }
        }},
      email: null
    };
  }

  showModal = (email) => {
    let template = this.getTemplateFromEmail(email);
    let product_interest =  this.props.productInterest[email].length == 0 ? "products" :  this.props.productInterest[email].join(" and ");
    let product_interest_type = 'manual';
    if (product_interest.includes('Automate')) {
      product_interest_type = 'automation';
    }
    let findString = "{{product_interest_" + product_interest_type + "}}";
    template.template.body = template.template.body.split(findString).join(product_interest);
    template.template.snippet.docs = template.template.snippet.docs.split(findString).join(product_interest);
    template.template.snippet.pricing = template.template.snippet.pricing.split(findString).join(product_interest);

    template.template.subject.forEach(function(subject, index, subjects) {
      subjects[index] = subjects[index].split(findString).join(product_interest);
    });

    this.setState({ 
      show: true,
      templateData: template,
      email: email
    });
  };

  hideModal = (e) => {
    this.setState({ show: false });
  };

  getTemplateFromEmail = (email) => {
    let segmentId = this.props.segmentHash[ email ];
    if (segmentId == undefined) {
      return {}
    }

    let config = this.props.templateHash[ segmentId.toString() ];
    if (config ==  undefined) {
      return {description: ''};
    }
    return config;
  }

 
  render() {
    const { leadInfo, segmentHash, templateHash, productInterest } = this.props;
    if (!leadInfo) return <div>Loading Lead Info Failed</div>;
    const leadArray = values(leadInfo);

    return (
      <>
        <Col xs="12">
          <Modal
            show={this.state.show}
            showDocSnippet={this.state.showDocSnippet}
            showPricingSnippet={this.state.showPricingSnippet}
            handleClose={ e => (this.hideModal(e))}
            templateData={this.state.templateData}
            email={this.state.email}
          >
            <p>Modal</p>
            <p>Data</p>
          </Modal>
          <h4>Lead Summary</h4>
          <LeadTable
          templateHash={templateHash}
          segmentHash={segmentHash}
          showTemplateModal={this.showModal}
          getTemplateFromEmail={this.getTemplateFromEmail}
          rows={leadArray} />
        </Col>
      </>
    );
  }
}

LeadInfoWidget.propTypes = {
  leadInfo: Proptype.array,

};

LeadTable.propTypes = {
  rows: Proptype.array,
  segmentHash: Proptype.object,
  templateHash: Proptype.object,
  productInterest: Proptype.object
};

export default LeadInfoWidget;
