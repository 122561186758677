import React from 'react';
import _baseTab from '../utils/_baseTab';
import { loadingSoon } from '../utils/loadingSoon';
import { Button } from 'reactstrap';

import './leadInfoSubTab.css';
import LeadInfoWidget from '../accountSummaryWidgets/leadInfoWidget';
import MqlEventsWidget from '../accountSummaryWidgets/mqlEventsWidget';

const LeadInfoSubTab = props => {
  const myRef = React.createRef();
  const executeScroll = () => myRef.current.scrollIntoView();
  const { isLoaded, loadedData } = props;
  if (!loadedData) {
    return <div>Something went wrong! Try reloading the page.</div>;
  }
  return (
    <>
      {loadingSoon(
        isLoaded?.leadInfo,
        <>
          <div className="jumpBtnContainer">
            <Button onClick={executeScroll} className="jumpBtn">
              MQL Events
            </Button>
          </div>
          <LeadInfoWidget
            leadInfo={loadedData?.leadInfo}
            segmentHash={loadedData?.segmentHash}
            templateHash={loadedData?.templateHash}
            productInterest={loadedData?.productInterest}
          />
          <MqlEventsWidget scrollRef={myRef} leadInfo={loadedData?.mqlEvents} />
        </>
      )}
    </>
  );
};

export default LeadInfoSubTab;
